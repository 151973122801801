import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import React from 'react';

const Spinner = () => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '3rem',
      }}
    >
      <LoadingOutlined style={{ fontSize: 48 }} spin />
    </div>
  );
};

const NoData = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    style={{ width: '100%', padding: '3rem' }}
  />
);

export const TableKit = {
  NoData,
  Spinner,
};
