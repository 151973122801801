import { message } from 'antd';
import React from 'react';
import { T } from '@transifex/react';
import { MutateFunction } from 'react-query';
import axios from 'axios';

import { mutateSendVerificationCode } from './sendVerificationCode';
import { mutateVertifyCode } from './verifyCode';
import { mutateUpdateContainers } from './updateContainers';
import { APIError } from '../consts/error';

type TVariables = {
  token: string;
  propertyId: string;
  verificationId: string;
};

const callGetAllNotificationContainers: MutateFunction<
  unknown,
  unknown,
  TVariables
> = ({ token, propertyId, verificationId }) => {
  return axios
    .get(
      `notifications/containers?verification_id=${verificationId}&property_id=${propertyId}`,
      {
        headers: {
          'X-API-Key': token,
        },
      }
    )
    .catch((e) => {
      if (e?.response?.data?.detail === 'Forbidden') {
        message.error(<T _str="Verification code expired" />);
        throw new APIError(e?.message, { ERROR_MESSAGE_HANDLED: true });
      } else {
        throw new APIError(e);
      }
    });
};

export const apiNotification = {
  mutateSendVerificationCode,
  callGetAllNotificationContainers,
  mutateVertifyCode,
  mutateUpdateContainers,
};
