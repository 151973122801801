import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
// eslint-disable-next-line import/no-cycle
import { Roles } from '../../../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import {
  CreateConfigFn,
  nonAuthenticatedRedirect,
  pathJoin,
} from '../../../../routing/utils';

// eslint-disable-next-line import/no-cycle
import Create from './routes/create';
// eslint-disable-next-line import/no-cycle
import ServiceTypeRedirect from './ServiceTypeRedirect';
// eslint-disable-next-line import/no-cycle
import SelfService from './routes/exact';

export type Keys =
  | `exact`
  | `create/:ticketTypeId`
  | `redirect/toCreateByServiceType/:serviceType`;

export const createRoutesMap = (prefix: string) => ({
  'create/:ticketTypeId': (ticketTypeId = ':ticketTypeId') =>
    pathJoin(prefix)('create')(ticketTypeId).chain,
  'redirect/toCreateByServiceType/:serviceType': (
    serviceType = ':serviceType'
  ) => pathJoin(prefix)('redirect/toCreateByServiceType')(serviceType).chain,
});

const authenticatedUser: Roles = {
  and: ['authenticated'],
};

// create-config function.
export const createConfig: CreateConfigFn<Keys> = (map) => [
  {
    key: 'create/:ticketTypeId',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    children: <Create />,
    path: map.app.selfService['create/:ticketTypeId']()._,
  },
  {
    key: 'redirect/toCreateByServiceType/:serviceType',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    children: <ServiceTypeRedirect />,
    path: map.app.selfService['redirect/toCreateByServiceType/:serviceType']()
      ._,
  },
  {
    key: 'exact',
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
    roles: authenticatedUser,
    // @ts-expect-error WIP
    children: React.createElement(withRouter(SelfService)),
    path: map.app.selfService._,
    exact: true,
  },
];
