import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button';
import { T } from '@transifex/react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../routing/routing-map';

type WrapperProps = {
  $tickets?: boolean;
  $completed?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;

  width: 100%;
  height: auto;

  /* Gray / gray-3 */

  background: #f5f5f5;
  border-radius: 5px;

  margin-bottom: 1rem;

  .collection-date-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #0f3f6b;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .icon {
    display: flex;
    margin-right: 1rem;
  }

  .collection-day {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;

    color: #0f3f6b;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .collection-date-wrapper {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    /* secondary/navy blue/navy blue 500 */

    color: #0f3f6b;
  }

  .icon {
    color: #0f3f6b;
    font-size: 1.5rem;
  }

  ${({ $tickets }) =>
    $tickets &&
    `
     background: #FFF1F0; 
  `};

  ${({ $completed, $tickets }) =>
    !$tickets &&
    $completed &&
    `
     background: #F6FFED; 
  `};

  @media screen and (min-width: 992px) {
    padding: 1.5rem;

    .text-wrapper {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .mobile-content {
      display: none;
    }
  }
`;

type CollectionContainer = {
  waste_fraction: { id: string; name: string };
  container_type: { id: string; name: string };
  pickup_setting: { id: string; name: string };
  container_id: string;
  ticket_id: string;
};

type CollectionProps = {
  date: string;
  containers: $TSFixMe[];
  tickets: number;
  completed?: boolean;
};

type ExpandableContentProps = {
  containers: CollectionContainer[];
};

const ExpandableContentWrapper = styled.div`
  cursor: default;
  background: #ffffff;
  padding: 1rem;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1.5rem;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;

  .expandable-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .container-information-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-view {
    display: none;
  }

  .text-ticket {
    text-transform: capitalize;
  }

  .container-waste-fraction {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;

    margin-right: 0.5rem;

    color: #0f3f6b;
  }

  .container-information {
    font-style: normal;
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5rem;

    color: grey;
  }

  .divider {
    width: 100%;
    margin: 1rem 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .divider:last-of-type {
    display: none;
  }

  @media screen and (min-width: 992px) {
    margin-top: 2rem;

    .ticket-information-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .text-view {
      display: inherit;
    }

    .text-ticket {
      text-transform: lowercase;
    }
  }
`;

const ExpandableContent = ({ containers }: ExpandableContentProps) => {
  const history = useHistory();

  return containers.length > 0 ? (
    <ExpandableContentWrapper key={123}>
      {containers?.map((node) => (
        <>
          <div className="expandable-content">
            <div className="container-information-content-wrapper">
              <div className="container-waste-fraction">
                {node?.waste_fraction}
              </div>
              <div className="container-information">
                <div>
                  <T
                    _str="Container type: {containerType}"
                    containerType={node?.container_type}
                  />
                </div>
                <div>
                  <T
                    _str="Pickup setting: {pickupSetting}"
                    pickupSetting={node?.pickup_setting}
                  />
                </div>
              </div>
            </div>
            {node?.ticket_id && (
              <Button
                onClick={() =>
                  history.push(
                    routingMap.app.collections[':ticketId'](node?.ticket_id)._
                  )
                }
                title={
                  <div className="button-text">
                    <div className="text-view">
                      <T _str="View" />
                      &nbsp;
                    </div>
                    <div className="text-ticket">
                      <T _str="ticket" />
                    </div>
                  </div>
                }
                type="default"
              />
            )}
          </div>
          <div className="divider" />
        </>
      ))}
    </ExpandableContentWrapper>
  ) : null;
};

type ExpanderProps = {
  expanded: boolean;
  tickets: number;
  containers: number;
};

const TicketCountWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  background: #ff4d4f;
  border-radius: 10px;
  padding: 0rem 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-right: 0.75rem;

  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;

  color: #ffffff;

  @media screen and (min-width: 992px) {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;

    margin-right: 1rem;

    padding: 0.25rem 1rem;
    border-radius: 20px;
  }
`;

const ExpanderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: end;

  .icon {
    font-size: 1rem;
    color: #1a4d7b;
    margin-right: 0rem;
  }

  .container-count-wrapper {
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    color: #111d2c;
  }

  @media screen and (min-width: 992px) {
    .icon {
      font-size: 1.5rem;
    }
  }
`;

const Expander = ({ tickets, expanded, containers }: ExpanderProps) => {
  return (
    <ExpanderWrapper>
      {tickets > 0 && (
        <TicketCountWrapper>
          {tickets}
          &nbsp;
          {tickets > 1 ? <T _str="tickets" /> : <T _str="ticket" />}
        </TicketCountWrapper>
      )}
      <div className="container-count-wrapper">
        <div className="container-counter">{containers}&nbsp;</div>
        <div className="container-text">
          {containers > 1 ? <T _str="containers" /> : <T _str="container" />}
          &nbsp;
        </div>
      </div>
      {expanded && (
        <div className="icon">
          <CaretUpOutlined />
        </div>
      )}
      {!expanded && (
        <div className="icon">
          <CaretDownOutlined />
        </div>
      )}
    </ExpanderWrapper>
  );
};

export const CollectionDisplay = ({
  date,
  tickets,
  containers,
  completed,
}: CollectionProps) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Wrapper
      $tickets={!!tickets}
      $completed={!!completed}
      onClick={() => setExpanded(!expanded)}
    >
      <Row style={{ width: '100%' }}>
        <Col span={12}>
          <div className="collection-date-wrapper">
            <div className="collection-date">{date}</div>
          </div>
        </Col>
        <Col span={12}>
          {containers.length > 0 && (
            <Expander
              expanded={expanded}
              tickets={tickets}
              containers={containers.length}
            />
          )}
        </Col>
      </Row>
      {expanded && (
        <ExpandableContent
          containers={containers?.map((e) => ({
            ticket_id: e.ticket_id,
            container_id: e.container?.container_id,
            pickup_setting: e.container?.pickup_setting.name,
            waste_fraction: e.container?.waste_fraction.name,
            container_type: e.container?.container_type.name,
          }))}
        />
      )}
    </Wrapper>
  );
};
