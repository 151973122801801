import React from 'react';
// eslint-disable-next-line import/no-cycle
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { RouteConfig, Roles } from '../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import {
  pathJoin,
  nonAuthenticatedRedirect,
  CreateConfigFn,
  Role,
} from '../../routing/utils';
// eslint-disable-next-line import/no-cycle
import SelfService, {
  createRoutesMap as createSelfServiceMap,
} from './routes/self-service/SelfService';
// eslint-disable-next-line import/no-cycle
import { createRoutesMap as createContainersRouteMap } from './routes/containers/route-config';
// eslint-disable-next-line import/no-cycle
import Containers from './routes/containers/Containers';
// eslint-disable-next-line import/no-cycle
import { createRoutesMap as createCollectionsRouteMap } from './routes/collections/route-config';
// eslint-disable-next-line import/no-cycle
import Collections from './routes/collections/Collections';
// eslint-disable-next-line import/no-cycle
import ViewSmsService from './routes/sms-service';

type Keys =
  | `self-service`
  | `containers`
  | `collections`
  | `finance`
  | `messages`
  | `sms-service`;

export const createRouteMap = (prefix: string) => ({
  selfService: {
    _: pathJoin(prefix)(`self-service`).value,
    ...createSelfServiceMap(pathJoin(prefix)(`self-service`).value.slice(1)),
  },
  containers: {
    _: pathJoin(prefix)(`containers`).value,
    ...createContainersRouteMap(pathJoin(prefix)(`containers`).value.slice(1)),
  },
  collections: {
    _: pathJoin(prefix)(`collections`).value,
    ...createCollectionsRouteMap(
      pathJoin(prefix)(`collections`).value.slice(1)
    ),
  },
  'sms-service': {
    _: pathJoin(prefix)('sms-service').value,
  },
});

const junk = createRouteMap('');
export type RouteMap = typeof junk;

const authenticatedUser: Roles = {
  and: ['authenticated'],
};

export const createConfig: CreateConfigFn<Keys> = (map) => {
  const arr: (Omit<RouteConfig<Keys>, 'fallback' | `roles`> & {
    roles?: Roles;
  })[] = [
    {
      key: 'self-service',
      path: map.app.selfService._,
      children:
        /* @ts-expect-error WIP */
        React.createElement(withRouter(SelfService)),
    },
    {
      key: 'containers',
      path: map.app.containers._,
      children:
        /* @ts-expect-error WIP */
        React.createElement(withRouter(Containers)),
    },
    {
      key: 'collections',
      path: map.app.collections._,
      children: <Collections />,
    },
    {
      key: 'sms-service',
      path: map.app['sms-service']._,
      children: <ViewSmsService />,
    },
  ];

  return [
    ...arr.map((c) => ({
      roles: authenticatedUser,
      fallback: (roles: Role[]) => nonAuthenticatedRedirect(map)(roles),
      ...c,
    })),
  ];
};
