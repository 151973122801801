/* eslint-disable react/prop-types */
import { LeftOutlined } from '@ant-design/icons';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';
import useMediaQuery from 'use-media-antd-query/es/useMediaQuery';
import { createPrefixer } from '../../../utils';
import { LanguagePicker } from '../../I18n';

const p = createPrefixer('wh-UI-Header-with-back-');

const classes = {
  wrapper: p`wrapper`,
  backIcon: p`back-icon`,
  title: p`title`,
  steps: p`steps`,
  titleAndBack: p`title-and-back-container`,
};

export type HeaderWithBackProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  icon?: ReactNode;
  title: string | ReactNode;
  onBack: () => void;
  stepProgression: ReactNode;
};

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  color: ${(props) => props.theme.darkColor};

  .${classes.backIcon} {
    font-size: 0.875rem;
    margin-right: 0.5rem;
  }

  .${classes.title} {
    font-size: 0.875rem;
  }

  .${classes.titleAndBack} {
    display: flex;
    flex-direction: row;
    line-height: 1.375rem;
  }

  @media screen and (min-width: 992px) {
    cursor: default;
    align-items: center;
    justify-content: space-between;

    .${classes.backIcon} {
      display: none;
    }

    .${classes.title} {
      font-size: 1rem;
    }

    .${classes.steps} {
      display: none;
    }
  }
`;

export const HeaderWithBack: FC<HeaderWithBackProps> = ({
  title,
  onBack,
  stepProgression,
}) => {
  const isMobile = useMediaQuery('(max-width: 996px)');
  return (
    <HeaderDiv
      className={classes.wrapper}
      onClick={isMobile ? onBack : () => null}
    >
      <div className={classes.titleAndBack}>
        <div className={classes.backIcon}>
          <LeftOutlined />
        </div>
        <div className={classes.title}>
          {typeof title === 'string' ? (
            <T _str="{title}" title={title} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{title}</>
          )}
        </div>
      </div>
      <LanguagePicker />
      <div className={classes.steps}>{stepProgression}</div>
    </HeaderDiv>
  );
};
