import { lowerCase } from 'lodash';
import React from 'react';
import { ConfigProvider } from 'antd';
import { useLocale, useT } from '@transifex/react';
import en from 'antd/lib/locale/en_US';
import { Locale } from 'antd/es/locale-provider';
import enGB from 'antd/es/locale/en_GB';
import daDK from 'antd/es/locale/da_DK';
import { TLocales } from '../transifex';

const AntdI18n: React.FC = ({ children }) => {
  const locale = useLocale();

  const t = useT();

  const txOptional = `(${lowerCase(t('optional'))})`;

  const antdLocaleMap: Record<TLocales, Locale> = {
    en: enGB,
    da: {
      ...daDK,
      Form: {
        ...daDK.Form,
        optional: txOptional,
        defaultValidateMessages: {
          ...daDK.Form?.defaultValidateMessages,
        },
      },
    },
  };

  return (
    <ConfigProvider locale={antdLocaleMap[locale as TLocales] || en}>
      {children}
    </ConfigProvider>
  );
};

export default AntdI18n;
