import React, { ReactNode } from 'react';
import { T } from '@transifex/react';
import styled from 'styled-components';
import { createPrefixer } from 'utils';

const p = createPrefixer('wh-help-point-');

const classes = {
  wrapper: p`wrapper`,
  ellipse: p`ellipse`,
  helpPointHeader: p`help-point-header`,
  helpPointText: p`help-point-text`,
};

export type HelpPointProps = {
  helpPointTitle: ReactNode | string;
  helpPointDesc: ReactNode | string;
};

const HelpPointDiv = styled.div`
  display: flex;
  flex-direction: row;

  .${classes.ellipse} {
    background-color: #c4c4c4;
    min-height: 2rem;
    max-height: 2rem;
    min-width: 2rem;
    max-width: 2rem;
    border-radius: 50%;
    margin-top: 1.25rem;
    margin-right: 1rem;
  }

  .${classes.helpPointHeader} {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .${classes.helpPointText} {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  @media screen and (min-width: 992px) {
    .${classes.ellipse} {
      margin-right: 2rem;
    }

    .${classes.helpPointHeader} {
      font-size: 1.25rem;
    }

    .${classes.helpPointText} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
`;

const HelpPoint: React.FC<HelpPointProps> = ({
  helpPointTitle,
  helpPointDesc,
}: HelpPointProps) => {
  return (
    <HelpPointDiv className={classes.wrapper}>
      <div className={classes.ellipse} />
      <div>
        <div className={classes.helpPointHeader}>
          {typeof helpPointTitle === 'string' ? (
            <T _str="{header}" header={helpPointTitle} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{helpPointTitle}</>
          )}
        </div>
        <div className={classes.helpPointText}>
          {typeof helpPointDesc === 'string' ? (
            <T _str="{desc}" desc={helpPointDesc} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{helpPointDesc}</>
          )}
        </div>
      </div>
    </HelpPointDiv>
  );
};

export default HelpPoint;
