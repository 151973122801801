import React, { useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { UI } from './ui';
import { PropertyContext } from '../../../../../context/property-context';
// eslint-disable-next-line import/no-cycle
import {
  useGetPropertyAgreements,
  useGetPropertyContainers,
} from '../../../../../api/property/property-containers';

export const ApiWrapper = ({
  jsx,
  autoState,
}: {
  jsx: $TSFixMe;
  autoState: $TSFixMe;
}) => {
  const { id: propertyId } = useContext(PropertyContext);
  const { data, isLoading } = useGetPropertyContainers(propertyId, {
    includeActiveAgreement: true,
    includeNextCollectionDate: true,
    includeTicketTypes: true,
  });

  const { data: agreements, isLoading: agreementsLoading } =
    useGetPropertyAgreements(propertyId);

  return (
    <UI
      jsx={jsx}
      autoState={autoState}
      loading={isLoading || agreementsLoading}
      containers={data?.data || []}
      agreements={agreements?.data}
    />
  );
};
