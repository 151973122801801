// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useProperty } from 'app/Property.store';
import React, { createContext, FC, ReactNode, useMemo } from 'react';

export type PropertyState = {
  id: string;
  type: {
    id: string;
    name: string;
  };
  location: {
    latitude: number;
    longitude: number;
    name: string;
  };
  unique_number: string;
  ticketTypes: Record<string, $TSFixMe>[];
};

const State = {
  id: '',
  type: {
    id: '',
    name: '',
  },
  location: {
    latitude: 0,
    longitude: 0,
    name: '',
  },
  unique_number: '',
  ticketTypes: [],
  ticketTypesMap: {},
};

type TState = typeof State & { ticketTypesMap: Record<string, $TSFixMe> };

export const PropertyContext = createContext<TState>(State);
export const PropertyDispatchContext = createContext(undefined);

export type PropertyContextProps = {
  children: ReactNode;
};

export const PropertyProvider: FC<PropertyContextProps> = ({ children }) => {
  const { data } = useProperty();

  const value = useMemo(() => {
    const ticketTypes =
      data.ticket_types?.map((e) => ({
        ...e,
        portalDescription: e.portal_description,
        portalDateLabel: e.portal_date_label,
        portalDateHelpText: e.portal_date_help_text,
        portalDateRequired: e.portal_date_required,
        portalDateHidden: e.portal_date_hidden,
        portalDropdownLabel: e.portal_dropdown_label,
        portalDropdownHelpText: e.portal_dropdown_help_text,
        portalDropdownRequired: e.portal_dropdown_required,
        portalDropdownHidden: e.portal_dropdown_hidden,
        portalDropdownOptions: e.portal_dropdown_options,
        portalNoteLabel: e.portal_note_label,
        portalNoteHelpText: e.portal_note_help_text,
        portalNoteRequired: e.portal_note_required,
        portalNoteHidden: e.portal_note_hidden,
        portalPriceShowZero: e.portal_price_show_zero,
        portalContactInformationNameRequired:
          e.portal_contact_information_name_required,
        portalContactInformationNextButtonLabel:
          e.portal_contact_information_next_button_label,
        portalContactInformationPhoneNumberRequired:
          e.portal_contact_information_phone_number_required,
        portalContactInformationEmailRequired:
          e.portal_contact_information_email_required,
        portalConfirmTitle: e.portal_confirm_title,
        portalConfirmSubtitle: e.portal_confirm_subtitle,
        portalServiceTypeBulkWasteLimitHelpText:
          e.portal_service_type_bulk_waste_limit_help_text,
        portalServiceTypeBulkWasteLimitLabel:
          e.portal_service_type_bulk_waste_limit_label,
        portalServiceTypeBulkWasteLimit: e.portal_service_type_bulk_waste_limit,
      })) || [];
    const ticketTypesMap = ticketTypes?.reduce(
      (acc: $TSFixMe, node: $TSFixMe) => ({
        ...acc,
        [node.id]: node,
      }),
      {}
    );

    return {
      ...data,
      ticketTypes,
      ticketTypesMap,
    };
  }, [data]);

  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  );
};
