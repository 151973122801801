import { message as antdMessage } from 'antd';
import React from 'react';
import { T } from '@transifex/react';

type TAPIErrorOpts = {
  ERROR_MESSAGE_HANDLED?: boolean;
};

const handleError = (e: unknown) => {
  const error: APIError = e as APIError;
  if (!error.opts?.ERROR_MESSAGE_HANDLED)
    antdMessage.error(<T _str="Error occured" />);
};

export class APIError extends Error {
  opts?: TAPIErrorOpts = {};

  constructor(message: string, opts?: TAPIErrorOpts) {
    super(message); // (1)
    this.name = 'APIError'; // (2)
    this.opts = opts;
  }
}

export const apiErrorUtils = { handleError };
