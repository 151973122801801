import React, { useContext, useEffect } from 'react';
import { Select } from 'antd';
import { useLanguages, useTX } from '@transifex/react';
import useLocalStorageState from 'use-local-storage-state';
import moment from 'moment';
import { CompanyContext } from '../context/company-context';

export function LanguagePicker() {
  const company = useContext(CompanyContext);
  const language = company?.default_language?.toLowerCase();
  const languages = useLanguages();
  const tx = useTX();
  const [locale, setLocale] = useLocalStorageState(
    'transifex-locale',
    language || languages[0]?.code || 'en'
  );

  const onChange = (val: string) => {
    setLocale(val);
  };

  useEffect(() => {
    tx.setCurrentLocale(locale);
    moment.locale(locale);
  }, [locale, tx]);

  return (
    <Select
      className="language-picker"
      onChange={onChange}
      options={languages.map(({ code, localized_name }: $TSFixMe) => ({
        value: code,
        label: localized_name,
      }))}
      value={locale}
    />
  );
}
