import { WhDatePicker } from '@wastehero/storybook/lib/components';
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useMemo } from 'react';
import { Form, Select, Row, Col, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
import { T } from '@transifex/react';
import { useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { VALIDATION_MESSAGE } from '../../../../../../../../utils/validation';
import OrderOverview from '../../../../../../../shared/OrderOverview';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyTicketPrices } from '../../../../../../../../api/property/ticket-prices';
import { PropertyContext } from '../../../../../../../../context/property-context';
import { PrefferedDate } from '../../elements/Summary';
import { createMarkUp } from '../../../../../../../../utils/createMarkup';
// eslint-disable-next-line import/no-cycle
import { useTicketAvailableDates } from '../useTicketAvailableDates';
import { DATE_FORMAT } from '../consts';

import { TFormHandler } from '../../types';

const Content: TFormHandler['Form']['Content'] = () => {
  const { id, ticketTypes } = useContext(PropertyContext);
  const { data } = useGetPropertyTicketPrices(id, {
    includeAddContainerPrices: true,
    includePreferredDates: true,
  });
  const { containerType, wasteFraction } =
    Form.useWatch(['manageContainers', 'addContainer']) || {};
  const form = Form.useFormInstance();
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const {
    portalDateHidden,
    portalDateLabel,
    portalDateHelpText,
    portalDateRequired,
    portalDescription,
    portalNoteHidden,
    portalNoteLabel,
    portalNoteHelpText,
    portalNoteRequired,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];

  const prices = useMemo(
    () => data?.data?.add_container_prices,
    [data?.data?.add_container_prices]
  );

  const availableDates = useTicketAvailableDates({
    propertyId: id,
    ticketTypeId,
  });

  const containerOptions = useMemo(
    () =>
      uniqBy(
        prices?.map((node: $TSFixMe) => ({
          value: node.container_type.id,
          label: node.container_type.name,
        })),
        (o: $TSFixMe) => o.value
      ),
    [prices]
  );

  const wasteFractionOptions = useMemo(
    () =>
      uniqBy(
        prices
          ?.map((node: $TSFixMe) => ({
            value: node.waste_fraction.id,
            label: node.waste_fraction.name,
            containerType: node.container_type.id,
          }))
          .filter((v: $TSFixMe) => v.containerType === containerType),
        (o: $TSFixMe) => o.value
      ),
    [containerType, prices]
  );

  const pickupSettingOptions = useMemo(
    () =>
      uniqBy(
        prices
          ?.map((node: $TSFixMe) => ({
            value: node.pickup_setting.id,
            label: node.pickup_setting.name,
            containerType: node.container_type.id,
            wasteFraction: node.waste_fraction.id,
          }))
          .filter(
            (v: $TSFixMe) =>
              v.containerType === containerType &&
              v.wasteFraction === wasteFraction
          ),
        (o: $TSFixMe) => o.value
      ),
    [containerType, prices, wasteFraction]
  );

  useEffect(() => {
    form.setFields([
      {
        name: ['manageContainers', 'addContainer', 'wasteFraction'],
        value: undefined,
      },
      {
        name: ['manageContainers', 'addContainer', 'pickupSetting'],
        value: undefined,
      },
    ]);
  }, [containerType, form]);

  useEffect(() => {
    form.setFields([
      {
        name: ['manageContainers', 'addContainer', 'pickupSetting'],
        value: undefined,
      },
    ]);
  }, [wasteFraction, form]);

  return (
    <div style={{ width: '100%' }}>
      {portalDescription && (
        <div
          style={{ marginBottom: '1rem' }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={createMarkUp(portalDescription)}
        />
      )}
      <Form.Item
        label={portalNoteLabel || <T _str="Note" />}
        name={['manageContainers', 'note']}
        tooltip={portalNoteHelpText || undefined}
        rules={[
          {
            required: portalNoteRequired,
            message: VALIDATION_MESSAGE.REQUIRED,
          },
        ]}
        hidden={portalNoteHidden}
      >
        <TextArea style={{ marginBottom: '1rem' }} />
      </Form.Item>
      <Row gutter={[16, 8]}>
        {[
          <Form.Item
            label={<T _str="Container type" />}
            name={['manageContainers', 'addContainer', 'containerType']}
            rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
          >
            <Select
              options={containerOptions}
              placeholder={<T _str="Container type" />}
            />
          </Form.Item>,
          <Form.Item
            label={<T _str="Waste fraction" />}
            name={['manageContainers', 'addContainer', 'wasteFraction']}
            rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
          >
            <Select
              placeholder={<T _str="Waste fraction" />}
              options={wasteFractionOptions}
              disabled={!containerType}
            />
          </Form.Item>,
          <Form.Item
            label={<T _str="Pickup setting" />}
            name={['manageContainers', 'addContainer', 'pickupSetting']}
            rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
          >
            <Select
              placeholder={<T _str="Pickup setting" />}
              options={pickupSettingOptions}
              disabled={!containerType || !wasteFraction}
            />
          </Form.Item>,
          <Form.Item
            label={portalDateLabel || <T _str="Preferred date" />}
            name={['manageContainers', 'addContainer', 'preferredDate']}
            tooltip={portalDateHelpText || undefined}
            rules={[
              {
                required: portalDateRequired,
                message: VALIDATION_MESSAGE.REQUIRED,
              },
            ]}
            hidden={portalDateHidden}
          >
            <WhDatePicker
              format={DATE_FORMAT}
              inputReadOnly
              style={{ width: '100%' }}
              disabledDate={(date) =>
                !(availableDates.get('default') || [])?.includes(
                  date.format('YYYY-MM-DD')
                )
              }
            />
          </Form.Item>,
        ].map((node) => (
          <Col xs={24} sm={12}>
            {node}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const BreadCrumbTitle = <T _str="Container specification" />;

const Summary = () => {
  const values = Form.useWatch(['manageContainers', 'addContainer']);

  const { id, ticketTypesMap } = useContext(PropertyContext);
  const { data } = useGetPropertyTicketPrices(id, {
    includeAddContainerPrices: true,
  });
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const ticketType = useMemo(
    () => ticketTypesMap?.[ticketTypeId],
    [ticketTypeId, ticketTypesMap]
  );

  const prices = useMemo(
    () => data?.data?.add_container_prices,
    [data?.data?.add_container_prices]
  );

  const container = prices?.filter(
    (price: $TSFixMe) =>
      price.container_type.id === values?.containerType &&
      price.pickup_setting.id === values?.pickupSetting &&
      price.waste_fraction.id === values?.wasteFraction
  )?.[0];

  const { portalPriceShowZero, portalDateLabel, portalDateHidden } = ticketType;

  const summaryItems = {
    icon: <DeleteOutlined />,
    header: container?.waste_fraction?.name,
    headerSuffix: <T _str="New container" />,
    description: (
      <Space>
        {`${container?.container_type?.name} / ${container?.pickup_setting?.name}`}
        <PrefferedDate
          value={values?.preferredDate}
          dateText={portalDateLabel}
          dateHidden={portalDateHidden}
        />
      </Space>
    ),
    // #TODO add price in the future
    price:
      container?.container_price > 0 || portalPriceShowZero
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          container?.container_price / 100 || 0
        : 0,
    recurringPrice: true,
  };

  return <OrderOverview header="" items={[summaryItems]} />;
};

export const FormAddContainer = { Form: { BreadCrumbTitle, Content }, Summary };
