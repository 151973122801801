import React, { useContext, useEffect, useState } from 'react';
import { Result } from 'antd';
import { T } from '@transifex/react';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { useRouting } from '../../../../routing/child-router-factory';
import { PropertyContext } from '../../../../context/property-context';

const ServiceTypeRedirect = () => {
  const { ticketTypes } = useContext(PropertyContext);
  const history = useHistory();
  const { serviceType } = useParams<{ serviceType: string }>();
  const { routingMap } = useRouting();
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    const ticketTypesForThisService = ticketTypes?.find(
      ({ service }: $TSFixMe) => service?.service_type === serviceType
    ) as $TSFixMe;
    if (ticketTypesForThisService) {
      history.replace({
        pathname: routingMap.app.selfService['create/:ticketTypeId'](
          ticketTypesForThisService?.id
        )._,
        state: history.location.state, // forwarding the state.
      });
    } else {
      setInvalid(true);
    }
  }, [history, routingMap.app.selfService, serviceType, ticketTypes]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {invalid && (
        <Result
          status="404"
          title={
            <T
              _str="Service type: {serviceType} not supported"
              serviceType={serviceType}
            />
          }
        />
      )}
    </div>
  );
};

export default ServiceTypeRedirect;
