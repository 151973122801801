/* eslint-disable @typescript-eslint/naming-convention */
import { T } from '@transifex/react';
import { Button, Form, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-cycle
import SelectableContainersList from '../../../../../new-components/SelectableContainersList';
import { VALIDATION_MESSAGE } from '../../../../../utils/validation';
import { StopPropagationDiv } from '../../../../shared/StopPropagationDiv';
import { useSmsServiceStore, TContainer } from '../store';
import { getCookie } from '../../../../../utils/cookies';
import { smsCodeCookie } from '../../../../../consts/cookie-consts';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../../routing/routing-map';

type TFormSchema = {
  containers: Record<string, { checked: boolean; daysBefore: number }>;
};

const FormSelectContianer = () => {
  const [form] = Form.useForm<TFormSchema>();
  const history = useHistory();

  const other = useSmsServiceStore.getState().data?.other;

  useEffect(() => {
    const newValue: TFormSchema['containers'] = useSmsServiceStore
      .getState()
      .data?.newSelectedContainers?.reduce(
        (acc, { id, daysBefore }) => ({
          ...acc,
          [id]: { checked: true, daysBefore },
        }),
        {}
      ) as unknown as TFormSchema['containers'];
    form.setFieldValue('containers', newValue || {});
    // only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    if (getCookie(smsCodeCookie)) {
      history.push(routingMap.app.selfService._);
    } else {
      useSmsServiceStore.getState().reset();
    }
  };

  useEffect(() => {
    if (getCookie(smsCodeCookie)) {
      useSmsServiceStore
        .getState()
        .setVerificationCode(getCookie(smsCodeCookie), {
          ...other,
        });
    }
  }, [other]);

  return (
    <Form
      scrollToFirstError
      form={form}
      onFinish={(values: TFormSchema) => {
        const newPayload = Object.entries(values.containers)
          .filter(([, v]) => v.checked)
          .map(([id, v]): TContainer => ({ id, daysBefore: v.daysBefore }));
        useSmsServiceStore.getState().updateContainers(newPayload, true);
      }}
      style={{ height: '100%' }}
    >
      <SelectableContainersList
        preName={['containers']}
        canBeEmpty
        renderItem={({ id, data }, checked) => {
          const {
            container: {
              container_id,
              waste_fraction: { name: wasteFraction },
            },
          } = data;
          return {
            title: (
              <>
                {wasteFraction} - {container_id}
              </>
            ),
            addonEnd: (
              <StopPropagationDiv>
                <Form.Item
                  name={['containers', id, 'daysBefore']}
                  dependencies={[['containers', id, 'checked']]}
                  rules={[
                    {
                      required: checked,
                      message: VALIDATION_MESSAGE.REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder={<T _str="Days before" />}
                    style={{ width: '200px' }}
                    disabled={!checked}
                    options={Array.from({ length: 30 }).map((_, d) => ({
                      label: (
                        <>
                          {d + 1}{' '}
                          {d > 0 ? <T _str="days" /> : <T _str="days" />}
                        </>
                      ),
                      value: d + 1,
                    }))}
                  />
                </Form.Item>
              </StopPropagationDiv>
            ),
          };
        }}
        footer={
          <Space
            style={{ width: '100%', justifyContent: 'end', marginTop: 16 }}
          >
            <Button onClick={onCancel}>
              <T _str="Cancel" />
            </Button>
            <Button type="primary" htmlType="submit">
              <T _str="Next" />
            </Button>
          </Space>
        }
      />
    </Form>
  );
};

export default FormSelectContianer;
