import React from 'react';
import { T, useT } from '@transifex/react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import download from 'downloadjs';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../../../routing/routing-map';

type CompleteCalendarProps = {
  data: $TSFixMe;
  setPdfData: $TSFixMe;
  filename: string;
};

const CompleteCalendar = ({
  data,
  setPdfData,
  filename,
}: CompleteCalendarProps) => {
  const t = useT();

  const closeString = t('Close');
  const downloadAgainString = t('Download again');
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <CheckCircleFilled style={{ fontSize: 64, color: '#52C41A' }} />
      <div
        style={{
          fontSize: '1.5rem',
          fontWeight: 600,
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <T _str="Download completed" />
      </div>
      <div
        style={{ fontSize: '0.875rem', fontWeight: 400, marginBottom: '1rem' }}
      >
        <T _str="The file has been downloaded, check the download folder on your device." />
      </div>
      <div>
        <Link to={routingMap.app.collections._}>
          <Button
            style={{ marginRight: '1rem' }}
            onClick={() => setPdfData(null)}
          >
            {closeString}
          </Button>
        </Link>
        <Button
          type="primary"
          onClick={() => {
            const content = data.headers['content-type'];
            download(data.data, filename, content);
          }}
        >
          {downloadAgainString}
        </Button>
      </div>
    </div>
  );
};

export default CompleteCalendar;
