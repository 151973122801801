import moment, { Moment } from 'moment';
import { ISelectValueType } from '@wastehero/storybook/lib/components/Form/Elements/Select/types';

export const getMonthIndex = (date: Moment) => {
  return moment(date).month();
};

export const getPreviousMonthIndex = (date: Moment) => {
  return moment(date).subtract(1, 'month').month();
};

export const getNextMonthIndex = (date: Moment) => {
  return moment(date).add(1, 'month').month();
};

export const getShortMonthName = (date: Moment) => {
  return moment(date).format('MMM');
};

export const getFullMonthName = (date: Moment) => {
  return moment(date).format('MMMM');
};

export const getYear = (date: Moment) => {
  return moment(date).format('YYYY');
};

export const formatDate = (date: Moment) => {
  return moment(date).format('LL');
};

export const getMonthRangeString = (
  monthIndex: number | string,
  yearIndex: number | string
) => {
  return `${moment([yearIndex, 0, 1])
    .month(monthIndex)
    .toISOString()}, ${moment([yearIndex, 0, 31])
    .month(monthIndex)
    .toISOString()}`;
};

export const getMonthRangeArray: (
  monthIndex: number | string,
  yearIndex: number | string
) => [moment.Moment, moment.Moment] = (
  monthIndex: number | string,
  yearIndex: number | string
) => {
  return [
    moment([yearIndex, 0, 1]).month(monthIndex),
    moment([yearIndex, 0, 31]).month(monthIndex),
  ];
};

export const getMonthRangeArrayFromMoment: (
  date: moment.Moment
) => [moment.Moment, moment.Moment] = (date: moment.Moment) => {
  return [
    moment([getYear(date), 0, 1]).month(getMonthIndex(date)),
    moment([getYear(date), 0, 31]).month(getMonthIndex(date)),
  ];
};

export const getCurrentMonthRange: () => [
  moment.Moment,
  moment.Moment
] = () => {
  return [
    moment([getYear(moment()), 0, 1]).month(getMonthIndex(moment())),
    moment([getYear(moment()), 0, 31]).month(getMonthIndex(moment())),
  ];
};

export const monthSelectValueToMomentRange: (
  selectValue: ISelectValueType
) => [moment.Moment, moment.Moment] = (selectValue: ISelectValueType) => {
  if (typeof selectValue !== 'number') {
    const dates = selectValue.split(', ');

    return [moment(dates[0]), moment(dates[1])];
  }

  return getCurrentMonthRange();
};
