import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { createPrefixer } from 'utils';
import { WasteHeroLogo1 } from 'svgs';

const p = createPrefixer('wh-login-screen-');

const classes = {
  wrapper: p`wrapper`,
  logo: p`logo`,
  center: p`center`,
  text: p`text`,
};

export type LoginContainerProps = {
  descriptiveText?: JSX.Element | false;
  logo: string;
  children?: ReactNode;
};

const LoginContainerDiv = styled.div`
  height: auto;
  background: white;
  padding: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  min-width: 320px;
  left: 15%;
  right: 15%;
  top: 10%;

  .${classes.logo} {
    font-size: 50px;
    margin-bottom: 1rem;
    max-height: 150px;
  }

  .${classes.center} {
    display: flex;
    justify-content: center;
  }

  .${classes.text} {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #111d2c;
  }

  .ant-select-selector {
    height: 2.5rem !important;
    align-items: center;
    font-size: 1rem;
  }

  .ant-select-arrow {
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.875rem;
  }

  .ant-btn {
    font-size: 1rem;
    line-height: 1.5rem;
    min-height: 2.938rem;
    padding: 0.4rem 0 0.4rem 0;
  }

  .ant-select {
    height: 2.5rem;
    display: block;
  }

  .ant-form-item {
    margin-right: 0px !important;
  }

  @media screen and (max-width: 480px) {
    left: 10%;
    right: 10%;
    min-width: 290px;
  }

  @media screen and (max-width: 320px) {
    left: 10%;
    right: 10%;
    min-width: 200px;
  }

  @media screen and (min-width: 992px) {
    left: 40%;
    padding: 3rem;
    width: 20%;
    top: 20%;

    font-size: 1rem;
    line-height: 1.5rem;

    .${classes.logo} {
      font-size: 100px;
      margin-bottom: 2rem;
      max-height: 150px;
    }

    .ant-divider {
      margin: 2rem 0 1rem 0;
    }

    .ant-btn {
      padding: 0px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      min-height: 2.75rem;
    }

    .ant-input-affix-wrapper {
      height: 2.5rem;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    }
  }
`;

export const LoginContainer: React.FC<LoginContainerProps> = ({
  logo,
  children,
  descriptiveText,
}: LoginContainerProps) => {
  return (
    <LoginContainerDiv className={classes.wrapper}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={classes.center}>
            {logo ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <img src={logo} alt="Login logo" width="100%" />
            ) : (
              <div className={classes.logo}>
                <WasteHeroLogo1 style={{ fontSize: 'inherit' }} />
              </div>
            )}
          </div>
        </Col>
        {descriptiveText && (
          <Col span={24} className={classes.text}>
            {descriptiveText}
          </Col>
        )}
        <Col span={24}>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            {children}
          </div>
        </Col>
      </Row>
    </LoginContainerDiv>
  );
};
