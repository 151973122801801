import React from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useTX } from '@transifex/react';
import {
  getMonthIndex,
  getMonthRangeArray,
  getYear,
  monthSelectValueToMomentRange,
} from '../../../../../utils';
import { GetCollections } from '../exact/api';
import { BrowserlessCalendar } from './component';
import { callGetOneProperty } from '../../../../../api/property';

export const Browserless = () => {
  const tx = useTX();

  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());
  const dateRange = `${params.from_date}, ${params.to_date}`;
  const key = params.api_key;
  const propertyId = params.property_id;

  const { language } = params;

  if (language) {
    moment.locale(language);
    tx.setCurrentLocale(language);
  }

  const dates = monthSelectValueToMomentRange(dateRange);

  const { data } = useQuery(
    [
      'get-property-collections',
      propertyId,
      moment(dates[0]).format(),
      moment(dates[1]).format(),
    ],
    () =>
      GetCollections({
        token: key,
        propertyId,
        fromDate: moment(dates[0]).format(),
        toDate: moment(dates[1]).format(),
      })
  );

  const { data: propertyData } = useQuery(
    ['get-property-browserless', propertyId, key],
    () => callGetOneProperty({ token: key, id: propertyId })
  );

  const collections = data?.data;

  const collectionDates: string[] = [];

  const convertDate = (date: string) => {
    return moment(date).format('YYYY-MM-DD');
  };

  collections?.map((e: $TSFixMe) => {
    if (e.completed_at) {
      if (!collectionDates.includes(convertDate(e.completed_at))) {
        collectionDates.push(convertDate(e.completed_at));
      }
    }
    if (!e.completed_at) {
      if (!collectionDates.includes(convertDate(e.scheduled_at))) {
        collectionDates.push(convertDate(e.scheduled_at));
      }
    }
    return null;
  });

  const groupedCollections = collectionDates.map((e) => {
    return collections.filter(
      (b: $TSFixMe) =>
        convertDate(b.completed_at) === e || convertDate(b.scheduled_at) === e
    );
  });

  const collectionsGroupedByMonth: $TSFixMe[][] = [];

  const groupCollectionsByMonth = () => {
    let lastPickupMonth = moment(
      groupedCollections?.[0]?.[0]?.scheduled_at
    ).format('YYYY-MM');

    let currentMonthsPickups: $TSFixMe[] = [];

    groupedCollections.map((e) => {
      if (moment(e?.[0]?.scheduled_at).format('YYYY-MM') !== lastPickupMonth) {
        collectionsGroupedByMonth.push(currentMonthsPickups);
        currentMonthsPickups = [];
        lastPickupMonth = moment(e?.[0]?.scheduled_at).format('YYYY-MM');
        currentMonthsPickups.push(e);
      } else {
        currentMonthsPickups.push(e);
      }

      return null;
    });

    collectionsGroupedByMonth.push(currentMonthsPickups);
  };

  if (dates && groupedCollections) {
    groupCollectionsByMonth();
  }

  return (
    <div style={{ height: '100%' }}>
      {collectionsGroupedByMonth.map((e) => (
        <div style={{ pageBreakAfter: 'always' }}>
          <BrowserlessCalendar
            data={e}
            initialRange={getMonthRangeArray(
              getMonthIndex(e?.[0]?.[0].scheduled_at),
              getYear(e?.[0]?.[0].scheduled_at)
            )}
            // @ts-expect-error WIP
            location={propertyData?.data?.location?.name}
          />
        </div>
      ))}
    </div>
  );
};
