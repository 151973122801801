import { T } from '@transifex/react';
import React from 'react';
import styled from 'styled-components';
import UploadMultipleWithButton from '@wastehero/storybook/lib/components/Upload/UploadMultipleWithButton';
import { ticketTicketStatusChoicesLabelMap } from './utills';

const TicketUIWrapper = styled.div`
  padding: 1rem;

  width: 100%;

  display: flex;
  flex-direction: column;

  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  .divider {
    width: 100%;
    margin: 1rem 0;
    border-bottom: 1px solid #d9d9d9;
  }

  .title-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;

    color: #8c8c8c;
  }

  .data-text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;

    color: #0f3f6b;
  }
`;

type UIProps = {
  ticketType: string;
  ticketStatus: string;
  container?: string;
  detail?: React.ReactNode;
  description?: string;
  images?: $TSFixMe;
  createdAt?: string;
  preferredDate?: string;
};

const NoDataWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #8c8c8c;
`;

const NoData = () => {
  return (
    <NoDataWrapper>
      <T _str="No data" />
    </NoDataWrapper>
  );
};

type DataWrapperProps = {
  title: string | React.ReactNode;
  data?: string | React.ReactNode;
  divider?: boolean;
  attachments?: { mime_type: string; url: string }[];
};

const AttachmentWrapper = ({ fileList }: { fileList: $TSFixMe }) => {
  const formattedFileList = fileList?.map((e: $TSFixMe) => ({
    type: e.mime_type,
    url: e.url,
    preview: e.url,
    name: e.url,
    uid: e.url,
    thumbUrl: e.url,
  }));
  return (
    <UploadMultipleWithButton
      fileList={formattedFileList}
      showHeader={false}
      disabled
    />
  );
};

const DataWrapper = ({
  title,
  data,
  divider,
  attachments,
}: DataWrapperProps) => {
  return (
    <div>
      {divider && <div className="divider" />}
      <div className="title-text">{title}</div>
      {!attachments && <div className="data-text">{data || <NoData />}</div>}
      {attachments && <AttachmentWrapper fileList={attachments} />}
    </div>
  );
};

export const UI = ({
  ticketStatus,
  ticketType,
  description,
  detail,
  createdAt,
  images,
  container,
  preferredDate,
}: UIProps) => {
  return (
    <TicketUIWrapper>
      <DataWrapper title={<T _str="Ticket type" />} data={ticketType} />
      <DataWrapper
        title={<T _str="Ticket status" />}
        // @ts-expect-error WIP
        data={ticketTicketStatusChoicesLabelMap[ticketStatus]}
        divider
      />
      <DataWrapper title={<T _str="Created on" />} data={createdAt} divider />
      {preferredDate && (
        <DataWrapper
          title={<T _str="Preferred date" />}
          data={preferredDate}
          divider
        />
      )}
      {container && (
        <DataWrapper title={<T _str="Container" />} data={container} divider />
      )}
      {detail && (
        <DataWrapper title={<T _str="Detail" />} data={detail} divider />
      )}
      {description && (
        <DataWrapper
          title={<T _str="Description" />}
          data={description}
          divider
        />
      )}
      {images?.length > 0 && (
        <DataWrapper title={<T _str="Images" />} attachments={images} divider />
      )}
    </TicketUIWrapper>
  );
};
