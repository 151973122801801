import React, { useContext } from 'react';
import {
  InputPhone as AntdInputPhone,
  TInputPhoneProps,
} from '@wastehero/storybook/lib/components';
import { useT } from '@transifex/react';
import { CompanyContext } from '../../context/company-context';

export const InputPhone = ({ ...props }: TInputPhoneProps) => {
  const { country } = useContext(CompanyContext);
  const t = useT();

  const phonePlaceHolder = t('Phone number');
  return (
    <AntdInputPhone
      country={country?.toLowerCase() || 'dk'}
      placeholder={phonePlaceHolder}
      {...props}
    />
  );
};
