import React from 'react';
import { T } from '@transifex/react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const GeneratingCalendar = () => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Spin indicator={loadingIcon} />
      <div
        style={{
          fontSize: '1.5rem',
          fontWeight: 600,
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        <T _str="Generating" />
      </div>
      <div style={{ fontSize: '0.875rem', fontWeight: 400 }}>
        <T _str="The download will start once the pdf has been generated." />
      </div>
    </div>
  );
};

export default GeneratingCalendar;
