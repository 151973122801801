import { T } from '@transifex/react';
import React, { useMemo, useState } from 'react';
import { Button, Result, ResultProps } from 'antd';
import createStore from 'zustand';
import { LayoutLogin } from 'layouts/Public.Auht';
import { CitizenProvider } from 'context/citizen-context';
import { apiClient, apiClientMethods } from 'api/client';
import { MitIdLogin } from 'components/MitIdLogin';
import { useQuery } from 'react-query';
import { LoadingPage } from '../components/shared';
import { useCompany } from './Company.store';

type TValue = {
  id: string;
  username: string;
  token: string;
  language: string;
  phone_number: string;
  email: string;
  name: string;
  anonymous: boolean;
};

type TStore = {
  data: TValue;
  logout: () => void;
};

export const useUserStore = createStore<TStore>(() => ({} as TStore));

export const useUser = () => {
  return useUserStore();
};

/*
- getToken: from AUTH_TOKEN or from search param or from the api.
- getUser: using the token

- If LOGIN_METHOD_NONE:
  - set property user and OK.
- If LOGIN_METHOD_MIDID_POST_SEARCH:
  - If CheckIfPropertyUserIsAnanymous is true then redirect to mit id.
  - else set property user and OK.
- If LOGIN_METHOD_MIDID_PRE_SEARCH
  - set property user and OK.

*/

export const UserStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { data: company, hostname } = useCompany();
  const { data: user } = useUser();
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const [error, setError] = useState<ResultProps>();

  const logout = () => {
    localStorage.setItem('AUTH_TOKEN', '');
    localStorage.setItem('AUTH_PROPERTY_ID', '');
    window.history.replaceState({}, document.title, '/');
    window.location.reload();
  };

  const { isLoading } = useQuery(['UserStoreProvider', 'Get'], async () => {
    // if there is error then show error and return
    const errorCode = searchParams.get('error_code');
    const errorMessage = searchParams.get('error');
    if (errorCode || errorMessage) {
      setError({
        title: errorMessage || errorCode,
        status: 'error',
        extra: (
          <Button onClick={logout} style={{ padding: '4px 15px' }}>
            <T _str="Back to login" />
          </Button>
        ),
      });
      return null;
    }

    // get the token
    const token =
      searchParams.get('api_key') || localStorage.getItem('AUTH_TOKEN');

    if (token) {
      // need to remove the api_key from the url once we used it.
      if (searchParams.get('api_key'))
        window.history.replaceState({}, document.title, '/');

      apiClientMethods.setToken(token);
      return apiClient
        .get('property_user')
        .then((d) => {
          if (d.data.id) {
            useUserStore.setState({ data: d.data, logout });
            localStorage.setItem('AUTH_TOKEN', token);
          }
        })
        .catch((e) => {
          setError({
            title: e?.message || <T _str="Something went wrong" />,
            status: 'error',
            extra: (
              <Button onClick={logout} style={{ padding: '4px 15px' }}>
                <T _str="Back to login" />
              </Button>
            ),
          });
        });
    }
    // if there is no token then fetch it using company login method.
    return apiClient
      .post(`company/${hostname}/login`, { username: '', password: '' })
      .then((d) => {
        if (d.data.id) {
          apiClientMethods.setToken((d.data as TValue).token);
          useUserStore.setState({ data: d.data, logout });
          localStorage.setItem('AUTH_TOKEN', (d.data as TValue).token);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          // wrong property selected. Not authorized. invalid token
          logout();
        }
      });
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  if (user) {
    if (
      company.login_method === 'user' &&
      company.login_method_user === 'post_auth_search' &&
      user.anonymous
    ) {
      // show mit id login here.
      return (
        <LayoutLogin>
          <MitIdLogin />
        </LayoutLogin>
      );
    }
    return <CitizenProvider>{children}</CitizenProvider>;
  }

  return (
    <LayoutLogin>
      <Result {...error} />
    </LayoutLogin>
  );
};
