import { SyncOutlined } from '@ant-design/icons';
import { T } from '@transifex/react';
import { Typography } from '@wastehero/storybook/lib/components';
import { Alert, Button, Form, Input, message, Radio, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { apiErrorUtils } from '../../../../../api/consts/error';
import { apiNotification } from '../../../../../api/notifications';

import { createOptionsFromLabelMap } from '../../../../../utils/create-options';
import { VALIDATION_MESSAGE } from '../../../../../utils/validation';
import { TNotificationMode, useSmsServiceStore } from '../store';
import {
  notificationModeContact2Map,
  notificationModeLabelMap,
} from './consts';
import { getCookie, setCookie } from '../../../../../utils/cookies';
import {
  smsCodeCookie,
  smsServiceMode,
  smsServicePhoneNumber,
} from '../../../../../consts/cookie-consts';
import { InputPhone } from '../../../../shared/InputPhone';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';

const notificationModeOptions = createOptionsFromLabelMap(
  notificationModeLabelMap
);

type TFormSchema = {
  step: 0 | 1;
  mode: TNotificationMode;
  phoneNumber?: string;
  email?: string;
  notificationCode: string;
};

const FormGetVerificationId = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<TFormSchema>();
  const token = useGetToken();
  const step: TFormSchema['step'] = Form.useWatch('step', form);
  const mode: TFormSchema['mode'] = Form.useWatch('mode', form);

  const initialValues = useMemo<TFormSchema>(
    () => ({
      mode: 'sms',
      step: 0,
      email: '',
      phoneNumber: '',
      notificationCode: '',
    }),
    []
  );

  const sendCode = async () => {
    const { phoneNumber, email } = form.getFieldsValue();
    // send the verification code.
    const { data } = await apiNotification.mutateSendVerificationCode({
      token,
      verification_type: mode,
      email,
      phone_number: phoneNumber,
    });

    if (data) {
      form.setFieldsValue({ mode, step: 1, email, phoneNumber });
      message.success(<T _str="Verification code sent" />);
    } else {
      form.setFieldsValue({ mode, step: 0, email, phoneNumber });
      message.error(<T _str="Error occured" />);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (step === 0) {
        await sendCode();
      } else {
        const { phoneNumber, email, notificationCode } = form.getFieldsValue();
        // verify the code. step === 1
        const { data }: $TSFixMe = await apiNotification.mutateVertifyCode({
          token,
          verification_type: mode,
          email,
          phone_number: phoneNumber,
          code: notificationCode,
        });
        useSmsServiceStore
          .getState()
          .setVerificationCode(data?.verification_id, {
            email,
            phoneNumber,
            mode,
          });
        if (data?.verification_id) {
          setCookie(smsCodeCookie, data.verification_id, 1 / 24);
          setCookie(smsServicePhoneNumber, phoneNumber || '', 1 / 24);
          setCookie(smsServiceMode, mode, 1 / 24);
        }
      }
      setIsSubmitting(false);
    } catch (e) {
      apiErrorUtils.handleError(e);
      setIsSubmitting(false);
    }
  };

  if (getCookie(smsCodeCookie)) {
    useSmsServiceStore
      .getState()
      .setVerificationCode(getCookie(smsCodeCookie), {
        phoneNumber: getCookie(smsServicePhoneNumber),
        mode: getCookie(smsServiceMode) as TNotificationMode,
      });
  }

  return (
    <Form
      requiredMark="optional"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="mode"
        hidden
        rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
      >
        <Radio.Group
          disabled={step === 1 || isSubmitting}
          options={notificationModeOptions}
        />
      </Form.Item>
      {mode === 'sms' && (
        <Form.Item
          name="phoneNumber"
          label={<T _str="Phone number" />}
          rules={[
            { required: true, message: VALIDATION_MESSAGE.REQUIRED },
            ({ getFieldValue }) => ({
              validator: () => {
                const phoneNumber = getFieldValue('phoneNumber');

                if (isValidPhoneNumber(phoneNumber)) return Promise.resolve();
                return Promise.reject();
              },
              message: VALIDATION_MESSAGE.PHONE_NUMBER,
            }),
          ]}
        >
          <InputPhone disabled={step === 1 || isSubmitting} />
        </Form.Item>
      )}
      {mode === 'email' && (
        <Form.Item
          name="email"
          label={<T _str="Email" />}
          rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
        >
          <Input type="email" disabled={step === 1 || isSubmitting} />
        </Form.Item>
      )}
      {step === 1 && (
        <Form.Item
          name="notificationCode"
          label={<T _str="Notification code" />}
          rules={[{ required: true, message: VALIDATION_MESSAGE.REQUIRED }]}
        >
          <Input disabled={isSubmitting} />
        </Form.Item>
      )}
      {step === 1 && (
        <Alert
          style={{ marginBottom: 16 }}
          showIcon
          type="info"
          message={
            <Typography variant="subHeader-16" weight="semi-bold">
              <T _str="We have sent you the code." />
            </Typography>
          }
          description={
            <T
              _str="We have send you a code to your {method}. Please enter the code you recieved to verify. It might take up to 2 minutes before you recieve it. 
If you didn’t recieve the code, please make sure the number you put in is correct."
              method={notificationModeContact2Map[mode]}
            />
          }
        />
      )}
      <Space style={{ width: '100%', justifyContent: 'end' }}>
        {step === 1 && (
          <Button onClick={() => form.setFieldsValue(initialValues)}>
            <T _str="Reset" />
          </Button>
        )}
        {step === 1 && (
          <Button
            icon={<SyncOutlined />}
            onClick={sendCode}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <span>
              <T _str="Resend code" />
            </span>
          </Button>
        )}
        <Form.Item shouldUpdate noStyle>
          {({ isFieldsTouched }) => (
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
              disabled={!isFieldsTouched() || isSubmitting}
            >
              {step === 0 && <T _str="Send me a confirmation code" />}
              {step === 1 && <T _str="Verify" />}
            </Button>
          )}
        </Form.Item>
      </Space>
      <Form.Item name="step" hidden />
    </Form>
  );
};

export default FormGetVerificationId;
