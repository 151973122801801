import { T } from '@transifex/react';
import * as React from 'react';
import styled from 'styled-components';
import { PhoneOutlined } from '@ant-design/icons';
import { darken } from 'polished';
import { createPrefixer } from '../../../utils';

const p = createPrefixer('wh-UI-Footer-');

const classes = {
  wrapper: p`wrapper`,
  container: p`container`,
  location: p`location`,
  icon: p`icon`,
  phoneNumber: p`phone-number`,
};

const FooterDiv = styled.div`
  display: flex;
  min-width: 100%;
  min-height: 2.5rem;
  height: 2.5rem;
  align-items: center;

  background-color: #f5f5f5;

  .${classes.container} {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .${classes.location} {
    display: flex;
    margin-right: 2rem;

    visibility: visible;
  }

  .${classes.icon} {
    display: flex;
    margin-right: 0.5rem;

    visibility: visible;
  }
  .${classes.phoneNumber} {
    display: flex;

    margin-right: fill;
    visibility: visible;
  }

  @media screen and (min-width: 992px) {
    padding: 0.5rem 4rem 0.5rem 4rem;
    min-width: 100%;
    height: 3rem !important;
    min-height: 3rem !important;
    background-color: ${(props) => darken(0.2, props.theme.primaryColor)};
    color: ${(props) => props.theme.lightColor};
    font-size: 0.8rem;

    .${classes.container} {
      justify-content: end;
    }
  }
`;

export type IFooter = {
  location: string;
  phoneNumber: string;
};

const Footer = ({ location, phoneNumber }: IFooter): JSX.Element => {
  return (
    <FooterDiv className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.location}>
          <T _str={location} />
        </div>
        <div className={classes.icon}>
          <PhoneOutlined />
        </div>
        <div className={classes.phoneNumber}>
          <T _str={phoneNumber} />
        </div>
      </div>
    </FooterDiv>
  );
};

export default Footer;
