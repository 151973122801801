import { useCompany } from 'app/Company.store';
import React, { FC } from 'react';
import styled from 'styled-components';
import { createPrefixer } from 'utils';

import bg from 'svgs/LayoutBg.svg';
import { LanguagePicker } from 'components/I18n';
import { LoginContainer } from './LoginContainer';
import { Faq, NavigationButton } from './HowToUseIt';

const p = createPrefixer('wh-login-page-');

const classes = {
  wrapper: p`wrapper`,
  beforeClick: p`before-click`,
  text1: p`text1`,
  text2: p`text2`,
  faqContent: p`faq-content`,
  faqWrapper: p`faq-wrapper`,
  img: p`img`,
  whiteLabelPicture: p`white-label-picture`,
};

interface ImageProps {
  imagePath: string;
}

const ImageContainer = styled.div<ImageProps>`
  width: 100vw;
  max-width: 100%;

  height: 60%;
  clip-path: ellipse(115% 69% at 50% 20%);
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat !important;

  @media screen and (min-width: 992px) {
    width: 100vw;
    max-width: 100%;

    height: 60%;
    clip-path: ellipse(63% 62% at 50% 26%);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  ${({ imagePath }) =>
    imagePath &&
    `
      background: url(${imagePath}); 
  `};
`;

const PageContainer = styled.div`
  min-height: 170%;
  height: 170%;
  width: 100vw;
  max-width: 100%;

  .${classes.beforeClick} {
    width: 100%;
    position: absolute;
    bottom: 15%;
  }

  .${classes.text1} {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .${classes.text2} {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1890ff;
  }

  .${classes.faqWrapper} {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .${classes.faqContent} {
    width: 80%;
    justify-content: center;
  }

  .ant-space-item {
    width: 100%;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  @media screen and (min-width: 992px) {
    min-height: 170%;
    height: 170%;
    width: 100vw;
    max-width: 100%;

    .${classes.beforeClick} {
      bottom: 13%;
    }

    .${classes.faqContent} {
      width: 40%;
    }
  }
`;

export const LayoutLogin: FC = ({ children }) => {
  const { data: companyData } = useCompany();

  return (
    <PageContainer className={classes.wrapper}>
      <div
        style={{
          zIndex: 1000,
          position: 'absolute',
          display: 'flex',
          top: '4rem',
          right: '3rem',
        }}
      >
        <LanguagePicker />
      </div>
      <ImageContainer imagePath={companyData?.login_logo || bg} />
      <div style={{ width: '100%' }}>
        <LoginContainer logo={companyData?.logo}>{children}</LoginContainer>
      </div>
      <div className={classes.beforeClick}>
        <NavigationButton />
      </div>
      <div className={classes.faqWrapper}>
        <div className={classes.faqContent}>
          <Faq />
        </div>
      </div>
    </PageContainer>
  );
};
