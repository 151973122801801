import React, { useMemo } from 'react';
import { apiClient } from 'api/client';
import { Helmet } from 'react-helmet';
import createStore from 'zustand';
import { CompanyProvider } from 'context/company-context';
import { Result } from 'antd';
import { T, useT } from '@transifex/react';
import { useQuery } from 'react-query';
import { isLocalhost, isVercel } from '../utils';
import { LoadingPage } from '../components/shared';

type TValue = {
  id: string;
  name: string;
  phone_number: string;
  country: string;
  default_language: string;
  login_logo: string;
  login_method: 'none' | 'user';
  login_method_user: 'pre_auth_search' | 'post_auth_search';
  logo: string;
  primary_color: string;
  primary_text_color: string;
  secondary_text_color: string;
  waste_fractions: [
    {
      id: string;
      name: string;
      waste_category: string;
      waste_substance: string;
      waste_types: [
        {
          id: string;
          name: string;
        }
      ];
    }
  ];
  crm_custom_text_login_title: string;
  crm_custom_text_login: string;
  crm_custom_text_home_title: string;
  crm_custom_text_home: string;
  authentication_urls: {
    acr_values: string;
    url: string;
    business: boolean;
  }[];
};

type TStore = {
  data: TValue;
  hostname: string;
};

const useCompanyStore = createStore<TStore>(() => ({} as TStore));

export const useCompany = (): TStore => useCompanyStore();

export const CompanyStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const t = useT();
  const hostname = useMemo(() => {
    if (isVercel) {
      return (
        // eslint-disable-next-line no-alert
        window.prompt('Type a crmhostname', '') ||
        'portal-development.wastehero.io'
      );
    }

    if (isLocalhost) {
      return 'portal-development.wastehero.io';
    }
    return window.location.hostname.replace(/^www\./, '');
  }, []);

  const { isLoading, isError, error, data } = useQuery(
    ['CompanyStoreProvider', 'GET'],
    async () => {
      return apiClient.get(`company/${hostname}`).then((d) => {
        if (d.data.id) {
          useCompanyStore.setState({ data: d.data as TValue, hostname });
        }
        return d;
      });
    }
  );

  if ((error as $TSFixMe)?.response?.data?.detail === 'Not Found') {
    // the domain is not set to any company.
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100%',
        }}
      >
        <Result
          status="404"
          title={
            <T
              _str="No DNS setup for hostname: {hostName}"
              hostName={hostname}
            />
          }
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100%',
        }}
      >
        <Result
          status="500"
          title={<T _str="Error occured: Please try later." />}
        />
      </div>
    );
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  const company = data?.data as TValue;

  return (
    <>
      <Helmet>
        <title>
          {t('Self-service - {companyName}', {
            companyName: company.name,
          }) || t('Citizen Portal')}
        </title>
        <link
          rel="icon"
          type="image/png"
          href={company.logo || 'https://not-found.png'} // to overwrite the previous cached logo of react-icon
          sizes="16x16"
        />
      </Helmet>
      <CompanyProvider>{children}</CompanyProvider>
    </>
  );
};
