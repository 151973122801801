import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';
import { CheckCircleFilled } from '@ant-design/icons';
import { createPrefixer } from '../../utils';

const p = createPrefixer('wh-order-complete-');

const classes = {
  wrapper: p`wrapper`,
  icon: p`icon`,
  title: p`title`,
  subtext: p`subtext`,
  buttons: p`buttons`,
  informationContainer: p`information-container`,
  mainContent: p`main-content`,
};

export type OrderCompleteProps = {
  orderCompleteTitle: string | ReactNode;
  orderCompleteSubtext: string | ReactNode;
  primaryButton?: ReactNode;
  secondaryButtons?: ReactNode[];
};

const OrderCompleteDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;

  .${classes.mainContent} {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }

  .${classes.icon} {
    font-size: 4.375rem;
    color: #52c41a;
  }

  .${classes.title} {
    font-size: 1.563rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }

  .${classes.subtext} {
    font-size: 0.833rem;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 1rem;
  }

  .ant-btn {
    height: 2.5rem;
    margin-bottom: 0.4rem;
    width: 100%;
  }

  .ant-btn .anticon {
    margin-right: 0.5rem;
  }

  .${classes.buttons} {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    width: 100%;

    .${classes.mainContent} {
      height: 25%;
    }

    .${classes.icon} {
      margin-right: 0.5rem;
    }

    .${classes.title} {
    }

    .${classes.subtext} {
    }

    .ant-btn {
      width: fit-content;
      margin: 1rem 0.5rem 0 0;
    }

    .${classes.buttons} {
      width: 100%;
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const OrderCompleteView: React.FC<OrderCompleteProps> = ({
  orderCompleteSubtext,
  orderCompleteTitle,
  primaryButton,
  secondaryButtons,
}) => {
  return (
    <OrderCompleteDiv className={classes.wrapper}>
      <div className={classes.mainContent}>
        <div className={classes.icon}>
          <CheckCircleFilled />
        </div>
        <div className={classes.title}>
          {typeof orderCompleteTitle === 'string' ? (
            <T _str="{title}" title={orderCompleteTitle} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{orderCompleteTitle}</>
          )}
        </div>
        <div className={classes.subtext}>
          {typeof orderCompleteSubtext === 'string' ? (
            <T _str="{subtext}" subtext={orderCompleteSubtext} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{orderCompleteSubtext}</>
          )}
        </div>
      </div>
      <div className={classes.buttons}>
        {primaryButton}
        {secondaryButtons?.map((button) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{button}</>
        ))}
      </div>
    </OrderCompleteDiv>
  );
};
