import { WhDatePicker } from '@wastehero/storybook/lib/components';
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo } from 'react';
import { Form, Row, Col, Space } from 'antd';
import { useParams } from 'react-router';
import { T } from '@transifex/react';
import TextArea from 'antd/es/input/TextArea';
import { HomeOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyTicketPrices } from '../../../../../../../../api/property/ticket-prices';
import { PropertyContext } from '../../../../../../../../context/property-context';
import { PrefferedDate, SummaryNote } from '../../elements/Summary';
import { TFormHandler } from '../../types';
import OrderOverview from '../../../../../../../shared/OrderOverview';
import { formatPayment } from '../../../../../../../../utils/currency';
import { VALIDATION_MESSAGE } from '../../../../../../../../utils/validation';
import { createMarkUp } from '../../../../../../../../utils/createMarkup';
// eslint-disable-next-line import/no-cycle
import { useTicketAvailableDates } from '../useTicketAvailableDates';
import { DATE_FORMAT } from '../consts';

const Content: TFormHandler['Form']['Content'] = ({ addonEnd }) => {
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const { id, ticketTypes } = useContext(PropertyContext);
  const { data } = useGetPropertyTicketPrices(id, {
    includePreferredDates: true,
    priceForTicketTypeId: ticketTypeId,
  });

  const availableDates = useTicketAvailableDates({
    propertyId: id,
    ticketTypeId,
  });

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const {
    portalDateHidden,
    portalDateLabel,
    portalDateHelpText,
    portalDateRequired,
    portalNoteRequired,
    portalNoteHidden,
    portalNoteLabel,
    portalNoteHelpText,
    portalPriceShowZero,
    portalDescription,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];

  const servicePrice = data?.data?.ticket_type_price?.service_price;
  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      {portalDescription && (
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={createMarkUp(portalDescription)}
        />
      )}
      {addonEnd}
      <Row gutter={[16, 8]}>
        {[
          <Form.Item
            label={portalDateLabel || <T _str="Preferred date" />}
            name={['simpleProperty', 'preferredDate']}
            tooltip={portalDateHelpText || undefined}
            rules={[
              {
                required: portalDateRequired,
                message: VALIDATION_MESSAGE.REQUIRED,
              },
            ]}
            hidden={portalDateHidden}
          >
            <WhDatePicker
              format={DATE_FORMAT}
              inputReadOnly
              style={{ width: '100%' }}
              disabledDate={(date) =>
                !(availableDates.get('default') || [])?.includes(
                  date.format('YYYY-MM-DD')
                )
              }
            />
          </Form.Item>,
          <Form.Item
            label={portalNoteLabel || <T _str="Note" />}
            name={['simpleProperty', 'note']}
            tooltip={portalNoteHelpText || undefined}
            rules={[
              {
                required: portalNoteRequired,
                message: VALIDATION_MESSAGE.REQUIRED,
              },
            ]}
            hidden={portalNoteHidden}
          >
            <TextArea />
          </Form.Item>,
        ].map((node) => (
          <Col xs={24} sm={24}>
            {node}
          </Col>
        ))}
      </Row>
      {/* Todo: IPFE-4686: Fix UI and format price to proper currency */}
      {(!!servicePrice || portalPriceShowZero) && (
        <Space>
          <T
            _str="Price: {price} / one time"
            price={formatPayment(servicePrice || 0, 'dkk')}
          />
        </Space>
      )}
    </Space>
  );
};

const BreadCrumbTitle: TFormHandler['Form']['BreadCrumbTitle'] = (
  <T _str="Ticket details" />
);

const Summary: TFormHandler['Summary'] = () => {
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const preferredDate = Form.useWatch(['simpleProperty', 'preferredDate']);
  const note = Form.useWatch(['simpleProperty', 'note']);
  const { id, ticketTypesMap } = useContext(PropertyContext);
  const { data } = useGetPropertyTicketPrices(id, {
    priceForTicketTypeId: ticketTypeId,
  });

  const ticketType = useMemo(
    () => ticketTypesMap?.[ticketTypeId],
    [ticketTypeId, ticketTypesMap]
  );

  const servicePrice = data?.data?.ticket_type_price?.service_price || 0;

  const {
    portalPriceShowZero,
    portalDateLabel,
    portalNoteLabel,
    portalDateHidden,
  } = ticketType;

  const summaryItem = {
    icon: <HomeOutlined />,
    header: ticketType?.name,
    description: (
      <Space direction="vertical">
        <PrefferedDate
          value={preferredDate}
          dateText={portalDateLabel}
          dateHidden={portalDateHidden}
        />
        <SummaryNote note={note} noteLabel={portalNoteLabel} />
      </Space>
    ),
    price: servicePrice,
    recurringPrice: false,
  };

  return (
    <OrderOverview
      priceShowZero={portalPriceShowZero}
      header=""
      items={[summaryItem]}
      subtotalText={
        servicePrice > 0 || portalPriceShowZero ? (
          <T _str="Amount due:" />
        ) : undefined
      }
      total={formatPayment(servicePrice, 'dkk')}
    />
  );
};

export const FormSimplePropertyTicket = {
  Form: { BreadCrumbTitle, Content },
  Summary,
};
