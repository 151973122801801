import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GlobalStyles } from 'theme';
import { GlobalStyles as StorybookStyles } from '@wastehero/storybook/lib/styles';
import RouterFactory, { useRouting } from '../routing/child-router-factory';
import { createConfig } from './route-config';

import { routingMap } from '../routing/routing-map';
import AntdI18n from './AntdI18n';
import { CompanyStoreProvider } from './Company.store';
import { ThemeStoreProvider } from './Theme.store';
import Router from './router';
import { UserStoreProvider } from './User.store';
import { PropertyStoreProvider } from './Property.store';

export const App = () => {
  const { routingMap: map } = useRouting();

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StorybookStyles />
      <GlobalStyles />
      {/* @ts-expect-error WIP */}
      <BrowserRouter>
        <CompanyStoreProvider>
          <ThemeStoreProvider>
            <UserStoreProvider>
              <PropertyStoreProvider>
                <AntdI18n>
                  <Router>
                    <RouterFactory
                      config={createConfig(map)}
                      defaultRedirect={routingMap.app.selfService._}
                    />
                  </Router>
                </AntdI18n>
              </PropertyStoreProvider>
            </UserStoreProvider>
          </ThemeStoreProvider>
        </CompanyStoreProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
