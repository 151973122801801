import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { FC, useEffect, useState } from 'react';
import { T, useTX } from '@transifex/react';
import { Button, Result } from 'antd';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export const TestErrorBoundary = () => {
  /* Returns a button which throws an error in the component
   * To Test if error boundary is working fine
   */
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error) {
      throw new Error();
    }
  }, [error]);

  return (
    <Button
      type="primary"
      style={{ zIndex: 1000, position: 'fixed', top: 0, left: 0 }}
      onClick={() => {
        setError(true);
      }}
    >
      <T _str="Throw Error" />
    </Button>
  );
};

const getFallback: (locale: string) => Sentry.FallbackRender =
  (locale) =>
  // eslint-disable-next-line react/prop-types
  ({ resetError, eventId }) => {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Result
          status="warning"
          title={<T _str="Oops! Something went wrong" />}
          extra={
            <>
              <Button onClick={resetError} type="primary" key="console">
                <T _str="Try again" />
              </Button>
              <Button
                onClick={() => {
                  Sentry.showReportDialog({
                    lang: locale,
                    dsn: process.env.REACT_APP_SENTRY_DSN,
                    eventId: eventId || undefined,
                  });
                }}
                type="primary"
                key="console"
              >
                <T _str="Report" />
              </Button>
            </>
          }
        />
      </div>
    );
  };

export const ErrorBoundary: FC = ({ children }) => {
  const tx = useTX();
  const currentLocale = tx.getCurrentLocale();
  const fallback = getFallback(currentLocale);

  return (
    // @ts-expect-error Will fix it later
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  );
};
