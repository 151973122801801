/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { T, useT } from '@transifex/react';
import styled from 'styled-components';
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { createPrefixer } from 'utils';

const p = createPrefixer('wh-login-help-before-');

const classes = {
  wrapper: p`wrapper`,
  faq: p`faq`,
  login: p`login`,
  arrow: p`arrow`,
};

const BeforeClickDiv = styled.div`
  .${classes.login} {
    text-align: center;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 0.5rem;
  }

  .${classes.faq} {
    text-align: center;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 0.5rem;
  }

  .${classes.faq} {
    text-align: center;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 0.5rem;
  }

  .${classes.arrow} {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    color: #fff;
  }

  @media screen and (min-width: 992px) {
    .${classes.login} {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 1rem;
    }

    .${classes.faq} {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 1rem;
    }
  }
`;

export const NavigationButton: React.FC = ({}) => {
  const [showBackToLogin, setShowBackToLogin] = useState(false);

  const listenScrollEvent = (scrollPos: number) => {
    scrollPos > 50 ? setShowBackToLogin(true) : setShowBackToLogin(false);
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        listenScrollEvent((window.pageYOffset / window.innerHeight) * 100);
      },
      { passive: true }
    );
  });

  const scrollOnClick = () => {
    if (showBackToLogin) {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else window.scroll({ top: window.innerHeight * 0.8, behavior: 'smooth' });
  };

  return (
    <BeforeClickDiv className={classes.wrapper}>
      <Row>
        <Col span={24}>
          {!showBackToLogin && (
            <div className={classes.login}>
              <T _str="How to use it?" />
            </div>
          )}
          {showBackToLogin && (
            <div className={classes.faq}>
              <T _str="Back to login?" />
            </div>
          )}
        </Col>
        <Col span={24}>
          <div className={classes.arrow}>
            {showBackToLogin && <UpCircleFilled onClick={scrollOnClick} />}
            {!showBackToLogin && <DownCircleFilled onClick={scrollOnClick} />}
          </div>
        </Col>
      </Row>
    </BeforeClickDiv>
  );
};
