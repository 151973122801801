import React, { useContext } from 'react';
import { T } from '@transifex/react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { OrderCompleteView } from '../../../../../../shared';
// eslint-disable-next-line import/no-cycle
import { useRouting } from '../../../../../../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import { RouterContext } from '../../../../../../../context/routing';
import { PropertyContext } from '../../../../../../../context/property-context';

export const OrderComplete = () => {
  const { routingMap } = useContext(RouterContext);

  const history = useRouting.useHistory();

  const { ticketTypes } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const {
    portalConfirmTitle,
    portalConfirmSubtitle,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];
  return (
    <OrderCompleteView
      orderCompleteSubtext={
        portalConfirmSubtitle || <T _str="We have received your order" />
      }
      orderCompleteTitle={portalConfirmTitle || <T _str="Order completed" />}
      secondaryButtons={[
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push(routingMap.app.selfService._)}
        >
          <span>
            <T _str="Back to self-service" />
          </span>
        </Button>,
      ]}
    />
  );
};
