import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import RouterFactory from '../../../../routing/child-router-factory';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../routing/routing-map';

// eslint-disable-next-line import/no-cycle
import { createConfig } from './route-config';
// eslint-disable-next-line import/no-cycle
import { RouterContext } from '../../../../context/routing';

// eslint-disable-next-line import/no-cycle
export { createRoutesMap } from './route-config';

function Collections() {
  const routing = useContext(RouterContext);

  const routeMap: typeof routingMap = routing.routingMap;

  return <RouterFactory config={createConfig(routeMap)} />;
}

export default Collections;
