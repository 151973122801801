import React, { useState } from 'react';
import { useT, T } from '@transifex/react';
import Select from '@wastehero/storybook/lib/components/Form/Elements/Select';
import { ISelectProps } from '@wastehero/storybook/lib/components/Form/Elements/Select/types';
import { useQuery } from 'react-query';
import { Empty } from 'antd';
import { useDebounce } from 'use-debounce';
import { apiClient } from 'api/client';

export type SelectPropertyProps = Pick<ISelectProps, `onChange`>;

export const SelectProperty: React.FC<SelectPropertyProps> = ({ onChange }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const queryVariables = { search: debouncedSearch?.[0] };

  const { data: queryData, isFetching } = useQuery(
    [`SelectProperty`, queryVariables.search],
    () => apiClient.get('property', { params: queryVariables }),
    {
      keepPreviousData: true,
      enabled: !!queryVariables.search,
    }
  );

  const t = useT();
  const statusPlaceholder = t('Search address');

  const onSearch = (value: string) => setSearch(value);

  const options = queryVariables.search
    ? queryData?.data?.map((property: $TSFixMe) => ({
        id: property.id,
        value: property.id,
        label: property.location.name,
        data: property,
      }))
    : [];

  const antd = {
    style: { flexDirection: 'column', marginRight: '10px', width: '100%' },
  };

  return (
    <Select
      className="property-select"
      showSearch
      placeholder={statusPlaceholder}
      options={options || []}
      onChange={onChange}
      notFoundContent={
        !queryVariables.search ? null : (
          <Empty
            description={<T _str="No address found" />}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      }
      searchValue={search}
      onSearch={onSearch}
      antd={antd}
      filterOption={false}
      loading={isFetching}
    />
  );
};
