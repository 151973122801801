import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { T } from '@transifex/react';

const StyledErrorDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const { Title } = Typography;

export const ErrorPage: React.FC = () => {
  return (
    <StyledErrorDiv>
      <Title level={4}>
        <T _str="Some error occurred, sorry man" />
      </Title>
    </StyledErrorDiv>
  );
};
