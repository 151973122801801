import { RightOutlined } from '@ant-design/icons';
import { Col, Grid, Image, Row } from 'antd';
import React, { useRef } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {
  Typography,
  useModalWithButton,
} from '@wastehero/storybook/lib/components';

const content = {
  bannerImages: [{ url: '/images/refa_banner_home_screen.png' }],
  news: [
    {
      id: '1',
      title: 'Bemærk',
      link: '',
      description:
        'Fra ultimo januar 2023 kan du se her på Selvbetjeningen hvornår den nye indsamlingsordning bliver aktuel for din husstand samt tage stilling til, hvad der skal ske med din gamle affaldsbeholder.',
      theme: {
        type: 'primary',
      },
    },
    {
      id: '2',
      title: 'Ny indsamlingsordning af Enfamilieboliger',
      link: 'https://www.refa.dk/indsamlingsordning-2023/enfamilieboliger/',
      description:
        '(villaer, rækkehuse, o.l.) med egne beholdere i dag, får de nye indsamlingsordninger fra midten af april og månederne herefter. Du kan læse om ordningen her.',
      theme: {
        type: 'primary',
      },
    },
    {
      id: '3',
      title: 'Ny indsamlingsordning af Etageejendomme',
      description:
        '(ejer- og lejelejligheder og nogle rækkehuse) med fælles affaldsløsning i dag, fortsætter med fællesløsning og får de nye indsamlingsordninger fra midten af april og månederne herefter. Du kan læse om ordningen her.',
      link: 'https://www.refa.dk/indsamlingsordning-2023/etageejendomme/',
      theme: {
        type: 'primary',
      },
    },
    {
      id: '4',
      title: 'Ny indsamlingsordning af Sommerhuse',
      description:
        'i sommerhusområderne får de nye indsamlingsordninger til oktober. Du kan læse om ordningen her.',
      link: 'https://www.refa.dk/indsamlingsordning-2023/sommerhuse/',
      theme: {
        type: 'primary',
      },
    },
    {
      id: '5',
      title: 'Hvordan kan jeg se min affaldsordning?',
      description:
        'Klik på punktet ”Beholdere” i menuen. Her ser du en oversigt over hvilke beholdere din husstand er tilmeldt med, samt hvor ofte de tømmes. Derudover er det muligt, at ændre på husstandens affaldsordning ved at klikke på de tre prikker.',
      theme: {
        type: 'secondary',
      },
    },
    {
      id: '6',
      title: 'Hvordan kan jeg se hvornår jeg får tømt mine beholdere?',
      description:
        'Klik på punktet ”Tømningsoversigt” i menuen. Her ser du en oversigt over husstandens kommende samt tidligere tømninger.',
      theme: {
        type: 'secondary',
      },
    },
  ],
};

const Wrapper = styled.div``;

const HomeContent = () => {
  const { xl, xs } = Grid.useBreakpoint();
  const elipsesRef = useRef<Record<string, boolean>>({});

  const {
    jsx: { modal: jsxModal },
    utils: { openModal, setState: setModalState },
  } = useModalWithButton<Record<string, $TSFixMe>>({
    // eslint-disable-next-line react/no-unstable-nested-components
    children: ({ state }: $TSFixMe) => {
      const { link, theme, title, description } = state?.content || {};
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
          style={{
            background: theme?.type === 'primary' ? '#034F77' : '#E5E7E8',
            color: theme?.type === 'primary' ? 'white' : 'black',
            borderRadius: 2,
            padding: 20,
          }}
          onClick={() => {
            if (link) {
              window.open(link);
            }
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                columnGap: 8,
              }}
            >
              <Typography
                variant="subHeader-20"
                weight="semi-bold"
                style={{ color: 'inherit' }}
              >
                {title}
              </Typography>

              {link && <RightOutlined />}
            </div>
          </div>
          <div>
            <Typography variant="subHeader-16" style={{ color: 'inherit' }}>
              {description}
            </Typography>
          </div>
        </div>
      );
    },
    modelProps: {
      title: '',
      footer: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width: '80%',
      centered: true,
      closable: false,
      bodyStyle: {
        padding: 0,
        borderRadius: 2,
      },
    },
  });

  return (
    <Wrapper className="wh-vertical-scroller-container" style={{ rowGap: 8 }}>
      {!xl && content.bannerImages.at(0)?.url && (
        <Image src={content.bannerImages.at(0)?.url} width="100%" />
      )}
      <div className="wh-vertical-scroller-container-scroll">
        {content.news.length && (
          <Row
            gutter={[8, 8]}
            style={{
              overflowY: xl ? 'scroll' : 'hidden',
              overflowX: xl ? 'hidden' : 'scroll',
              height: '100%',
            }}
            wrap={xl}
          >
            {xl && content.bannerImages.at(0)?.url && (
              <Col flex={xl ? 'auto' : 'none'}>
                <Image src={content.bannerImages.at(0)?.url} width="100%" />
              </Col>
            )}
            {content.news.map(({ description, theme, title, link, id }) => (
              <Col flex={xl ? 'auto' : 'none'}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className={classnames({
                    hoverable: !!link,
                    'dark-10-hover': !!link,
                  })}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: xl ? 'unset' : '350px',
                    height: xl ? 'unset' : '100%',
                    background:
                      theme.type === 'primary' ? '#034F77' : '#E5E7E8',
                    color: theme.type === 'primary' ? 'white' : 'black',
                    padding: 20,
                    borderRadius: 8,
                  }}
                  onClick={() => {
                    if (xs) {
                      setModalState({
                        content: { description, theme, title, link, id },
                      });
                      openModal();
                      return;
                    }

                    if (link) {
                      window.open(link);
                    }
                  }}
                >
                  <div>
                    <Row>
                      <Col flex="auto">
                        <Typography
                          variant="subHeader-20"
                          weight="semi-bold"
                          style={{ color: 'inherit' }}
                        >
                          {title}
                        </Typography>
                      </Col>
                      {link && (
                        <Col flex="none">
                          <RightOutlined />
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div>
                    <Typography
                      variant="subHeader-16"
                      style={{ color: 'inherit' }}
                      component="Paragraph"
                      ellipsis={{
                        rows: xs ? 4 : 7,
                        onEllipsis: (elipses) => {
                          if (elipsesRef.current) {
                            elipsesRef.current[id] = elipses;
                          }
                        },
                      }}
                    >
                      {description}
                    </Typography>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
      {jsxModal}
    </Wrapper>
  );
};

export default HomeContent;
