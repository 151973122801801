import { Calendar } from 'antd';
import { T } from '@transifex/react';
import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  getMonthIndex,
  getMonthRangeArray,
  getMonthRangeArrayFromMoment,
  getShortMonthName,
  getYear,
} from '../../../../../../utils';
import { PropertyContext } from '../../../../../../context/property-context';
import { StyledCalendar } from '../../../../../shared/styles/StyledCalendar';

type CollectionCalendarProps = {
  data: $TSFixMe;
  onChange: (range: [Moment, Moment]) => void;
  initialRange: [Moment, Moment];
};

export const CollectionCalendar = ({
  data,
  onChange,
  initialRange,
}: CollectionCalendarProps) => {
  const [value, setValue] = useState<Moment>(initialRange?.[0]);
  const { location } = useContext(PropertyContext);

  const onSelect = (newValue: Moment) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(initialRange?.[0]);
  }, [initialRange]);

  const onRightClick = () => {
    onChange(getMonthRangeArrayFromMoment(moment(value).add(1, 'month')));
  };

  const onLeftClick = () => {
    onChange(getMonthRangeArrayFromMoment(moment(value).subtract(1, 'month')));
  };

  const dateCellRender = (date: Moment) => {
    const listData = data
      ?.flat()
      .filter(
        (e: $TSFixMe) => e.scheduled_at === moment(date).format('YYYY-MM-DD')
      );

    return (
      <div>
        {listData?.map((item: $TSFixMe) => (
          <div className="dot-container">
            <div className="dot" />
            <div className="event-text">
              {item?.container?.waste_fraction?.name}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const onPanelChange = (e: Moment) => {
    onChange(getMonthRangeArray(getMonthIndex(e), getYear(e)));
  };

  return (
    <StyledCalendar>
      <div className="print-info">
        <div>
          <T _str="Address: {location}" location={location?.name} />
        </div>
        <div>
          <T _str="Printed on: {date}" date={moment().format('LL')} />
        </div>
      </div>
      <div style={{ width: '100%', marginBottom: '1rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              fontSize: '1rem',
              alignItems: 'center',
              marginRight: '2rem',
              color: 'rgba(0, 0, 0, 0.85)',
            }}
          >
            <LeftOutlined onClick={() => onLeftClick()} />
          </div>
          <div style={{ fontWeight: 800, fontSize: '1.5rem' }}>
            {moment(initialRange?.[0]).format('YYYY')}{' '}
            {getShortMonthName(moment(initialRange?.[0]))}
          </div>
          <div
            style={{
              display: 'flex',
              fontSize: '1rem',
              alignItems: 'center',
              marginLeft: '2rem',
              color: 'rgba(0, 0, 0, 0.85)',
            }}
          >
            <RightOutlined onClick={() => onRightClick()} />
          </div>
        </div>
      </div>
      <Calendar
        dateCellRender={dateCellRender}
        value={value}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
        style={{ pageBreakAfter: 'avoid', pageBreakInside: 'avoid' }}
      />
    </StyledCalendar>
  );
};
