import { T } from '@transifex/react';
import { Typography } from '@wastehero/storybook/lib/components';
import { Space } from 'antd';
import { useGetToken } from 'api/config';
import { useCompany } from 'app/Company.store';
import React, { CSSProperties, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

import MidIDIcon from './MitID_logo.svg';

const LoginBtn = styled.button`
  background: #0060e6;
  color: #ffffff;
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
  border-radius: 4px;
  border: none;
  height: 48px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #004cb8;
  }
`;

const MitIdLogin = ({
  onClick,
  style,
}: {
  onClick?: () => void;
  style?: CSSProperties;
}) => {
  const { data: company } = useCompany();
  const token = useGetToken();

  return (
    <Space direction="vertical" style={{ textAlign: 'center' }} size={16}>
      {company.authentication_urls.map(({ business, url }) => (
        <Space direction="vertical">
          <Typography weight="semi-bold">
            {business ? <T _str="Business" /> : <T _str="Personal" />}
          </Typography>
          <LoginBtn
            style={style}
            onClick={() => {
              onClick?.();
              window.location.href = url.concat(`?api_key=${token}`);
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              src={MidIDIcon}
              style={{ width: '50px', marginRight: '1rem' }}
            />
            <span>Log ind med MitID</span>
          </LoginBtn>
        </Space>
      ))}
    </Space>
  );
};

const useMitIdLogin = () => {
  const { data: company } = useCompany();
  const token = useGetToken();
  const [jsx, setJsx] = useState<ReactNode>(null);

  const redirectToMitId = useCallback(() => {
    if (company.authentication_urls.length === 1) {
      window.location.href = company.authentication_urls[0].url.concat(
        `?api_key=${token}`
      );
    } else {
      setJsx(<MitIdLogin />);
    }
  }, [company.authentication_urls, token]);

  return {
    jsx,
    redirectToMitId,
  };
};

MitIdLogin.useMitIdLogin = useMitIdLogin;

export { MitIdLogin };
