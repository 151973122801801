import React from 'react';

import Main from '../components/main';

import {
  authenticatedRedirect,
  nonAuthenticatedRedirect,
  CreateConfigFn,
} from '../routing/utils';

import { Browserless } from '../components/main/routes/collections/browerless';
import { TestErrorBoundary } from '../sentry';

type Keys = `login` | `app` | 'calendar' | 'error-test';

export const createConfig: CreateConfigFn<Keys> = (map) => [
  {
    key: 'calendar',
    fallback: (roles) => authenticatedRedirect(map)(roles, `/something-wrong`),
    roles: {
      and: ['non-authenticated'],
    },
    children: <Browserless />,
    path: map.calendar._,
  },
  {
    key: 'error-test',
    roles: {
      and: ['non-authenticated'],
    },
    children: <TestErrorBoundary />,
    path: map['error-test']._,
    exact: true,
    fallback: (roles) => authenticatedRedirect(map)(roles, `/something-wrong`),
  },
  {
    key: 'app',
    path: map.app._,
    roles: {
      and: ['authenticated'],
    },
    children: <Main />,
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
  },
];
