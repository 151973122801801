import styled from 'styled-components';
import React from 'react';
import { createPrefixer } from 'utils';
import { Col, Row } from 'antd';
import { T } from '@transifex/react';
import { useCompany } from 'app/Company.store';
import { createMarkUp } from 'utils/createMarkup';
import { CONTENT_AUTH_SCREEN } from 'content/AuthScreen';
import HelpPoint from './Components/HelpPoint';

const p = createPrefixer('wh-login-help-after-');

const classes = {
  wrapper: p`wrapper`,
  header: p`header`,
  description: p`description`,
  helpContent: p`help-content`,
};

const AfterClickDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  .${classes.header} {
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .${classes.description} {
    font-size: 0.875rem;
    text-align: left;
    word-wrap: break-word;
    line-height: 1.375rem;
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: 992px) {
    .${classes.header} {
      font-size: 1.563rem;
      margin-bottom: 3rem;
      margin-top: 4rem;
    }

    .${classes.description} {
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 2.5rem;
    }

    .${classes.helpContent} {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Faq: React.FC = () => {
  const { data: companyData } = useCompany();

  if (
    companyData?.crm_custom_text_login ||
    companyData?.crm_custom_text_login_title
  ) {
    return (
      <AfterClickDiv className={classes.wrapper}>
        <Row>
          <Col span={24}>
            <div className={classes.header}>
              {companyData.crm_custom_text_login_title}
            </div>
          </Col>
          <Col span={24}>
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={createMarkUp(
                companyData?.crm_custom_text_login
              )}
            />
          </Col>
        </Row>
      </AfterClickDiv>
    );
  }

  return (
    <AfterClickDiv className={classes.wrapper}>
      <Row>
        <Col span={24}>
          <div className={classes.header}>
            <T _str="How to use this platform?" />
          </div>
        </Col>
        <Col span={24}>
          <div className={classes.description}>
            {CONTENT_AUTH_SCREEN.description}
          </div>
        </Col>
        <div className={classes.helpContent}>
          {CONTENT_AUTH_SCREEN.faqs?.map((v) => (
            <HelpPoint helpPointTitle={v.title} helpPointDesc={v.description} />
          ))}
        </div>
      </Row>
    </AfterClickDiv>
  );
};
