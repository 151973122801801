import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useCompany } from './Company.store';

type TTheme = {
  primaryColor: string;
  darkColor: string;
  lightColor: string;
};

export const ThemeStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { data: company } = useCompany();

  const theme: TTheme = {
    primaryColor: company.primary_color || '#1890FF',
    darkColor: company.primary_text_color || '#00417e',
    lightColor: company.secondary_text_color || '#fff',
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
