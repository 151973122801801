import { ReactNode } from 'react';
import { TOpt } from './types';

export const createOptionsFromLabelMap = <T extends string>(
  map: Record<T, ReactNode>
): TOpt<T>[] =>
  (Object.keys(map) as T[]).map((k) => ({
    label: map[k],
    value: k,
  }));
