import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import moment, { Moment } from 'moment';
// eslint-disable-next-line import/no-cycle
import { UI } from './ui';
import { PropertyContext } from '../../../../../context/property-context';
import { GetCollections } from './api';
import { getCurrentMonthRange } from '../../../../../utils';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../../api/config';

export const ApiWrapper = () => {
  const { id: propertyId } = useContext(PropertyContext);
  const token = useGetToken();

  const [dates, setDates] = useState<[Moment, Moment]>(getCurrentMonthRange());

  const { data, isLoading } = useQuery(
    [
      'get-property-collections',
      propertyId,
      moment(dates[0]).format(),
      moment(dates[1]).format(),
    ],
    () =>
      GetCollections({
        token,
        propertyId,
        fromDate: moment(dates[0]).format(),
        toDate: moment(dates[1]).format(),
      })
  );

  const collections = data?.data;

  const collectionDates: string[] = [];

  const convertDate = (date: string) => {
    return moment(date).format('LL');
  };

  collections?.map((e: $TSFixMe) => {
    if (e.completed_at) {
      if (!collectionDates.includes(convertDate(e.completed_at))) {
        collectionDates.push(convertDate(e.completed_at));
      }
    }
    if (!e.completed_at) {
      if (!collectionDates.includes(convertDate(e.scheduled_at))) {
        collectionDates.push(convertDate(e.scheduled_at));
      }
    }
    return null;
  });

  const groupedCollections = collectionDates.map((e) => {
    return collections.filter(
      (b: $TSFixMe) =>
        convertDate(b.completed_at) === e || convertDate(b.scheduled_at) === e
    );
  });

  return (
    <UI
      rangePickerProps={{ initialRange: dates, onChange: setDates }}
      collections={groupedCollections}
      loading={isLoading}
    />
  );
};
