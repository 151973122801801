import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import { T } from '@transifex/react';
import styled from 'styled-components';
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button';
import { createPrefixer } from '../../../../../utils';

const p = createPrefixer('wh-UI-Responsive-Sidebar-user-information-');

const classes = {
  wrapper: p`wrapper`,
  addressParagraph: p`address-paragraph`,
  userName: p`user-name`,
  avatarAndName: p`avatar-and-name`,
};

export type UserInformationProps = {
  authenticated: boolean;
  information: {
    name?: string;
    addresses: ReactNode;
  };
  onClick: () => void;
};

const UserInformationDiv = styled.div`
  width: 100%;
  background: #f5f5f5;
  padding: 1rem;
  height: fit-content;

  .${classes.addressParagraph} {
    width: 100%;
    font-size: 14px;
  }

  .ant-btn-link {
    padding: 0 !important;
  }

  .${classes.userName} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 10.5rem;
    font-size: 1rem;
  }

  .${classes.avatarAndName} {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 992px) {
    .${classes.addressParagraph} {
      font-size: 1rem;
    }
  }
`;

const UserInformation: React.FC<UserInformationProps> = ({
  authenticated,
  information,
  onClick,
}) => {
  return (
    <UserInformationDiv className={classes.wrapper}>
      <Row gutter={[8, 8]}>
        {/*        {authenticated && information && (
          <Col span={24}>
            <div className={classes.avatarAndName}>
              <AvatarWithName
                wrapperProps={{ width: '100%' }}
                avatarProps={{
                  size: { xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 32 },
                }}
              >
                <strong className={classes.userName}>
                  {information.name || <T _str="Unknown" />}
                </strong>
              </AvatarWithName>
            </div>
          </Col>
        )} */}
        <Col span={24}>
          <div className={classes.addressParagraph}>
            {information.addresses}
          </div>
        </Col>
        {!authenticated && onClick && (
          <Col span={24}>
            <Button
              type="link"
              title={<T _str="Change address" />}
              onClick={onClick}
            />
          </Col>
        )}
      </Row>
    </UserInformationDiv>
  );
};

export default UserInformation;
