import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  .wh-vertical-scroller-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &> .wh-vertical-scroller-container-scroll {
      flex: 1;
      min-height: 0;
    }
  }

  .hoverable  {
    &:hover {
      cursor: pointer;
    }
  }

  .dark-10-hover {
    &:hover {
      filter: brightness(90%);
    }
  }

`;
