import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Result, Space } from 'antd';
import { T } from '@transifex/react';
import createStore from 'zustand';
import { PropertyProvider } from 'context/property-context';
import { LayoutLogin } from 'layouts/Public.Auht';
import { SelectProperty } from 'components/SelectProperty';
import { apiClient } from 'api/client';
import { useQuery } from 'react-query';
import { MitIdLogin } from 'components/MitIdLogin';
import { useCompany } from './Company.store';
import { LoadingPage } from '../components/shared';
import { useUser } from './User.store';
// import { useMitId } from './useMitId';

export type TValue = {
  id: string;
  type: {
    id: string;
    name: string;
  };
  location: {
    latitude: number;
    longitude: number;
    name: string;
  };
  ticket_types: [
    {
      id: string;
      name: string;
      service: {
        id: string;
        name: string;
        service_type: string;
      };
      is_container_level: true;
      is_property_level: true;
      portal_require_contact_information: true;
      portal_description: string;
      portal_date_label: string;
      portal_date_help_text: string;
      portal_date_required: true;
      portal_date_hidden: true;
      portal_note_label: string;
      portal_note_help_text: string;
      portal_note_required: true;
      portal_note_hidden: true;
      portal_price_show_zero: true;
      portal_contact_information_name_required: true;
      portal_contact_information_phone_number_required: true;
      portal_contact_information_email_required: true;
      portal_contact_information_next_button_label: string;
      portal_confirm_title: string;
      portal_confirm_subtitle: string;
      portal_service_type_bulk_waste_limit: number;
      portal_service_type_bulk_waste_limit_label: string;
      portal_service_type_bulk_waste_limit_help_text: string;
      portal_dropdown_hidden: true;
      portal_dropdown_required: true;
      portal_dropdown_label: string;
      portal_dropdown_help_text: string;
      portal_dropdown_options: [string];
    }
  ];
};

type TStore = {
  data: TValue;
  computed: {
    ticketTypesMap: Record<string, TValue['ticket_types'][0]>;
  };
};

const usePropertyStore = createStore<TStore>(() => ({} as TStore));

export const useProperty = () => {
  return usePropertyStore();
};

/*
1. Get propertyId

- get property id from the localStorage. if not set then go to the property selection page.
- if we get the id from selection page then set it on the localStorage.

2. Once we have the property id we need to check if anything else is required

- If LOGIN_METHOD_NONE:
  - set property and OK.
- If LOGIN_METHOD_MIDID_POST_SEARCH:
  - set property and OK.
- If LOGIN_METHOD_MIDID_PRE_SEARCH:
  - If CheckIfPropertyPropertyIsAnanymous is true then redirect to mid id.
  - else set proerty and OK.

*/

export const PropertyStoreProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [selectPropertyJsx, setSelectPropertyJsx] =
    useState<JSX.Element | null>(null);
  const [notAllowed, setNotAllowed] = useState(false);
  const { data: company } = useCompany();
  const { jsx: jsxMitIdLogin, redirectToMitId } = MitIdLogin.useMitIdLogin();
  const { data: user, logout } = useUser();

  useEffect(() => {
    if (
      company.login_method === 'user' &&
      company.login_method_user === 'pre_auth_search' &&
      user.anonymous
    ) {
      // if the user is pre_auth_search and still ananoyms then reset the token.
      localStorage.setItem('AUTH_PROPERTY_ID', '');
    }
  }, [company.login_method, company.login_method_user, user.anonymous]);

  const { isLoading, refetch, isRefetching } = useQuery(
    ['PropertyStoreProvider', 'Get'],
    async () => {
      setNotAllowed(false); // reseting the state when get's refetch again
      let propertyId = localStorage.getItem('AUTH_PROPERTY_ID');

      if (!propertyId) {
        propertyId = await new Promise<string>((res) => {
          const jsx = (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SelectProperty
                onChange={(v) => {
                  if (
                    company.login_method === 'user' &&
                    company.login_method_user === 'pre_auth_search' &&
                    user.anonymous
                  ) {
                    localStorage.setItem('AUTH_PROPERTY_ID', v as string);
                    localStorage.setItem(
                      'AUTH_TOKEN_EXPIRY',
                      moment().add(60, 'minutes').toISOString()
                    );
                    redirectToMitId();
                  } else {
                    res(v as string);
                  }
                }}
              />
              {company.login_method_user === 'post_auth_search' && (
                <Button
                  type="primary"
                  style={{
                    padding: '4px 15px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={() => {
                    // trigger the selection process again
                    logout();
                  }}
                >
                  <T _str="Logout" />
                </Button>
              )}
            </div>
          );
          setSelectPropertyJsx(jsx);
        }).then((v) => {
          localStorage.setItem('AUTH_PROPERTY_ID', v as string);
          localStorage.setItem(
            'AUTH_TOKEN_EXPIRY',
            moment().add(60, 'minutes').toISOString()
          );
          setSelectPropertyJsx(null);
          return v;
        });
      }

      // if there is no token then fetch it using company login method.
      return apiClient
        .get(`property/${propertyId}`)
        .then((d) => {
          if (d.data.id) {
            const p = d.data as TValue;
            usePropertyStore.setState({
              data: d.data,
              computed: {
                ticketTypesMap: p.ticket_types.reduce(
                  (acc, t) => ({ ...acc, [t.id]: t }),
                  {}
                ),
              },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            // wrong property selected. Not authorized.
            setNotAllowed(true);
          }
        });
    }
  );

  if (jsxMitIdLogin) return <LayoutLogin>{jsxMitIdLogin}</LayoutLogin>;
  if (selectPropertyJsx) return <LayoutLogin>{selectPropertyJsx}</LayoutLogin>;

  if (isLoading || isRefetching) {
    return <LoadingPage />;
  }

  if (notAllowed) {
    return (
      <LayoutLogin>
        <Result
          status="error"
          title={<T _str="You are not authorized to the selected property. " />}
          subTitle={
            <T _str="Please make sure you selected the right property." />
          }
          extra={
            <Space
              wrap
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Button
                type="primary"
                style={{ padding: '4px 15px' }}
                onClick={() => {
                  // trigger the selection process again
                  localStorage.setItem('AUTH_PROPERTY_ID', '');
                  refetch();
                }}
              >
                <T _str="Return to property search" />
              </Button>
              <MitIdLogin
                style={{ margin: 'auto' }}
                onClick={() => {
                  localStorage.setItem('AUTH_TOKEN', '');
                  localStorage.setItem('AUTH_PROPERTY_ID', '');
                }}
              />
            </Space>
          }
        />
      </LayoutLogin>
    );
  }

  return <PropertyProvider>{children}</PropertyProvider>;
};
