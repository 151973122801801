import { sumBy } from 'lodash';
import { WhDatePicker } from '@wastehero/storybook/lib/components';
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo } from 'react';
import { Form, Row, Col, Input, InputNumber } from 'antd';
import { T, useT } from '@transifex/react';
import { useParams } from 'react-router-dom';
import { VALIDATION_MESSAGE } from '../../../../../../../../utils/validation';
// eslint-disable-next-line import/no-cycle
import SelectWasteFractionsList from '../SelectableWastefractionsList';
import OrderOverview from '../../../../../../../shared/OrderOverview';
import { PropertyContext } from '../../../../../../../../context/property-context';
// eslint-disable-next-line import/no-cycle
import { useGetPropertyTicketPrices } from '../../../../../../../../api/property/ticket-prices';
import { PrefferedDate } from '../../elements/Summary';
import { formatPayment } from '../../../../../../../../utils/currency';
import { StopPropagationDiv } from '../../../../../../../shared/StopPropagationDiv';
import { createMarkUp } from '../../../../../../../../utils/createMarkup';
// eslint-disable-next-line import/no-cycle
import { useTicketAvailableDates } from '../useTicketAvailableDates';
import { DATE_FORMAT } from '../consts';
import { TFormHandler } from '../../types';

const Description = () => {
  const { id, ticketTypes } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const availableDates = useTicketAvailableDates({
    propertyId: id,
    ticketTypeId,
  });

  const {
    portalDateHidden,
    portalDateLabel,
    portalDateHelpText,
    portalDateRequired,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];

  return (
    <Form.Item
      label={portalDateLabel || <T _str="Preferred date" />}
      name={['bulkWastePickup', 'preferredDate']}
      tooltip={portalDateHelpText || undefined}
      rules={[
        {
          required: portalDateRequired,
          message: VALIDATION_MESSAGE.REQUIRED,
        },
      ]}
      hidden={portalDateHidden}
    >
      <WhDatePicker
        format={DATE_FORMAT}
        inputReadOnly
        disabledDate={(date) =>
          !(availableDates.get('default') || [])?.includes(
            date.format('YYYY-MM-DD')
          )
        }
      />
    </Form.Item>
  );
};

const Content: TFormHandler['Form']['Content'] = ({ addonEnd }) => {
  const { ticketTypes } = useContext(PropertyContext);
  const { ticketTypeId } = useParams<{ ticketTypeId: string }>();
  const t = useT();

  const ticketType: $TSFixMe = ticketTypes?.filter(
    (e: { id: string }) => e.id === ticketTypeId
  );

  const {
    portalNoteRequired,
    portalNoteHidden,
    portalNoteLabel,
    portalNoteHelpText,
    portalPriceShowZero,
    portalDescription,
    portalServiceTypeBulkWasteLimitHelpText,
    portalServiceTypeBulkWasteLimitLabel,
    portalServiceTypeBulkWasteLimit,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = ticketType?.[0];

  return (
    <div className="wh-vertical-scroller-container" style={{ rowGap: 32 }}>
      {portalDescription && (
        <div
          style={{ marginBottom: '1rem' }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={createMarkUp(portalDescription)}
        />
      )}
      {addonEnd}
      <div>
        <Description />
      </div>
      <div className="wh-vertical-scroller-container-scroll">
        <SelectWasteFractionsList
          preName={['bulkWastePickup', 'items']}
          renderItem={({ id, data }, checked) => {
            const { waste_fraction: wasteFraction, price } = data;
            return {
              title: wasteFraction?.name,
              addonEnd:
                (!!price || portalPriceShowZero) &&
                formatPayment(price || 0, 'dkk'),
              addonContent: !portalNoteHidden ? (
                <Row gutter={[16, 8]} style={{ width: '100%' }}>
                  <Col>
                    <StopPropagationDiv>
                      <Form.Item
                        rules={[
                          {
                            required: checked,
                            message: VALIDATION_MESSAGE.REQUIRED,
                          },
                          {
                            min: 1,
                            type: 'number',
                            message: VALIDATION_MESSAGE.MIN(1),
                          },
                          ({ getFieldValue, setFields }) => ({
                            validator: () => {
                              const totalSum = sumBy(
                                Object.values(
                                  getFieldValue(['bulkWastePickup', 'items']) ||
                                    {}
                                ) || [],
                                'quantity'
                              );
                              const ids = Object.keys(
                                getFieldValue(['bulkWastePickup', 'items'])
                              );

                              if (totalSum > portalServiceTypeBulkWasteLimit) {
                                ids?.map((e) =>
                                  setFields([
                                    {
                                      name: [
                                        'bulkWastePickup',
                                        'items',
                                        e,
                                        'quantity',
                                      ],
                                      errors: [
                                        (
                                          <T
                                            _str="Maximum {label} is: {max} for all waste fractions"
                                            label={
                                              portalServiceTypeBulkWasteLimitLabel
                                            }
                                            max={
                                              portalServiceTypeBulkWasteLimit
                                            }
                                          />
                                        ) as unknown as string,
                                      ],
                                    },
                                  ])
                                );
                                return Promise.reject();
                              }
                              ids?.map((e) =>
                                setFields([
                                  {
                                    name: [
                                      'bulkWastePickup',
                                      'items',
                                      e,
                                      'quantity',
                                    ],
                                    errors: [],
                                  },
                                ])
                              );
                              return Promise.resolve();
                            },
                            message: (
                              <T
                                _str="Maximum {label} is: {max} for all waste fractions"
                                label={portalServiceTypeBulkWasteLimitLabel}
                                max={portalServiceTypeBulkWasteLimit}
                              />
                            ),
                          }),
                        ]}
                        name={['bulkWastePickup', 'items', id, 'quantity']}
                        label={
                          portalServiceTypeBulkWasteLimitLabel || (
                            <T _str="Quantity" />
                          )
                        }
                        tooltip={
                          portalServiceTypeBulkWasteLimitHelpText || undefined
                        }
                      >
                        <InputNumber
                          type="number"
                          style={{ width: '160px' }}
                          disabled={!checked}
                          placeholder={
                            portalServiceTypeBulkWasteLimitLabel ||
                            t('Quantity')
                          }
                          min={1}
                          step={1}
                          precision={0}
                        />
                      </Form.Item>
                    </StopPropagationDiv>
                  </Col>
                  <Col xs={24} lg={24}>
                    <StopPropagationDiv>
                      <Form.Item
                        rules={[
                          {
                            required: portalNoteRequired && checked,
                            message: VALIDATION_MESSAGE.REQUIRED,
                          },
                        ]}
                        name={['bulkWastePickup', 'items', id, 'note']}
                        label={portalNoteLabel || <T _str="Note" />}
                        tooltip={portalNoteHelpText || undefined}
                        hidden={portalNoteHidden}
                        dependencies={[
                          ['bulkWastePickup', 'items', id, 'checked'],
                        ]}
                      >
                        <Input.TextArea disabled={!checked} />
                      </Form.Item>
                    </StopPropagationDiv>
                  </Col>
                </Row>
              ) : undefined,
            };
          }}
        />
      </div>
    </div>
  );
};

const BreadCrumbTitle = <T _str="Select waste fractions" />;

const Summary = () => {
  const { id: propertyId, ticketTypesMap } = useContext(PropertyContext);
  const { data } = useGetPropertyTicketPrices(propertyId, {
    includeBulkWastePrices: true,
  });
  const { ticketTypeId } = useParams<{
    ticketTypeId: string;
  }>();
  const ticketType = useMemo(
    () => ticketTypesMap?.[ticketTypeId],
    [ticketTypeId, ticketTypesMap]
  );
  const wasteFractionsMap = useMemo(
    () =>
      data?.data?.bulk_waste_prices?.reduce(
        (acc: $TSFixMe, node: $TSFixMe) => ({
          ...acc,
          [node.waste_fraction.id]: {
            id: node.waste_fraction.id,
            data: node,
          },
        }),
        {}
      ),
    [data]
  );
  const values = Form.useWatch(['bulkWastePickup']);

  const { portalDateLabel, portalPriceShowZero, portalDateHidden } = ticketType;

  const items = Object.keys(values?.items || {})
    .map((containerId) => ({
      ...values?.items?.[containerId],
      id: containerId,
    }))
    .filter((n) => n.checked)
    .map(({ id, estimatedVolume, estrimatedWeight, note }) => ({
      wasteFraction: id,
      note,
      estimatedWeight: estrimatedWeight,
      estimatedVolume,
    }));

  const summaryItems = items?.map((item: $TSFixMe) => ({
    header: wasteFractionsMap[item?.wasteFraction]?.data?.waste_fraction?.name,
    headerSuffix: ``,
    description: item.note,
    price: wasteFractionsMap[item?.wasteFraction]?.data?.price,
  }));

  const reducer = (currentValue: $TSFixMe, previousValue: $TSFixMe) =>
    previousValue + currentValue;

  const totalPrice =
    summaryItems.length > 0
      ? summaryItems?.map((item) => item.price).reduce(reducer, 0)
      : 0;

  return (
    <OrderOverview
      header={
        <PrefferedDate
          value={values?.preferredDate}
          dateText={portalDateLabel}
          dateHidden={portalDateHidden}
        />
      }
      items={summaryItems}
      subtotalText={
        totalPrice > 0 || portalPriceShowZero ? (
          <T _str="Amount due:" />
        ) : undefined
      }
      // #TODO add price when available
      total={formatPayment(totalPrice, 'dkk')}
    />
  );
};

export const FormBulkWastePickup = {
  Form: { BreadCrumbTitle, Content, Description },
  Summary,
};
