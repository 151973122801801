import { T } from '@transifex/react';
import { Col, Space, Steps } from 'antd';
import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@wastehero/storybook/lib/components';
import { PropertyContext } from '../../../../context/property-context';
import { Layout1, Layout2 } from '../../../layout';
// eslint-disable-next-line import/no-cycle
import FormGetVerificationId from './components/FormGetVerificationId';
// eslint-disable-next-line import/no-cycle
import FormSelectContianer from './components/FormSelectContianer';
// eslint-disable-next-line import/no-cycle
import { Success } from './components/Success';
// eslint-disable-next-line import/no-cycle
import { Summary } from './components/Summary';
import { useSmsServiceStore, TViewStep } from './store';

import { StepsStyles } from './styles';
import { BreadCrumb } from '../../../layout/breadcrumb';
import { Header, HeaderWithBack } from '../../../layout/header';
import { StepProgression } from '../../../shared';
import { getCookie } from '../../../../utils/cookies';
import { smsCodeCookie } from '../../../../consts/cookie-consts';
// eslint-disable-next-line import/no-cycle
import { routingMap } from '../../../../routing/routing-map';
import {
  notificationModeContactMap,
  notificationModeLabelMap,
} from './components/consts';
// eslint-disable-next-line import/no-cycle
import { useGetToken } from '../../../../api/config';

const header = <T _str="Notifications" />;

const stepLabelMap: Record<TViewStep, ReactNode> = {
  '0': <T _str="Select notification method" />,
  '1': <T _str="Select containers" />,
  '2': <T _str="Confirmation" />,
};
const stepContentMap: Record<TViewStep, FC> = {
  '0': FormGetVerificationId,
  '1': FormSelectContianer,
  '2': Summary,
};

const ViewSelfSerivce = () => {
  const history = useHistory();
  const token = useGetToken();
  const { id: propertyId } = useContext(PropertyContext);

  const init = useSmsServiceStore((store) => store.init);
  const step = useSmsServiceStore((store) => store.step);
  const done = useSmsServiceStore((store) => store.done);

  useEffect(() => {
    if (!init) {
      useSmsServiceStore
        .getState()
        .initStore({ context: { propertyId, token } });
    }
  }, [init, propertyId, token]);

  useEffect(() => {
    return () => {
      useSmsServiceStore.getState().reset();
    };
  }, []);

  const disabledStepState = useMemo<Partial<Record<TViewStep, boolean>>>(
    () => ({
      [TViewStep.GET_VERIFICATION_CODE]: step > TViewStep.GET_VERIFICATION_CODE,
      [TViewStep.SELECT_CONTAINERS]: step === TViewStep.GET_VERIFICATION_CODE,
      [TViewStep.SUMMARY]: step !== TViewStep.SUMMARY,
    }),
    [step]
  );

  if (!init) return null;

  const StepContent = stepContentMap[step];

  if (done) {
    return (
      <Layout1
        mainContent={<Success />}
        header={<Header title={header} />}
        breadcrumb={<div />}
      />
    );
  }

  const onBack = () => {
    if (step > 1) {
      useSmsServiceStore.getState().prevStep();
    } else if (getCookie(smsCodeCookie)) {
      history.push(routingMap.app.selfService._);
    } else if (step > 0) {
      useSmsServiceStore.getState().prevStep();
    } else {
      history.goBack();
    }
  };

  const other = useSmsServiceStore.getState().data?.other;

  return (
    <Layout2
      sideContent={
        <Col span={24}>
          <StepsStyles>
            <Steps
              onChange={(v) =>
                useSmsServiceStore.setState({ step: v as TViewStep })
              }
              direction="vertical"
              current={step}
              status={step < 3 ? 'process' : 'finish'}
            >
              {(Object.keys(stepLabelMap) as unknown as TViewStep[]).map(
                (k) => (
                  <Steps.Step
                    title={stepLabelMap[k as TViewStep]}
                    disabled={disabledStepState[k as TViewStep]}
                  />
                )
              )}
            </Steps>
          </StepsStyles>
        </Col>
      }
      breadcrumb={<BreadCrumb onBack={onBack} title={stepLabelMap[step]} />}
      header={
        <HeaderWithBack
          title={header}
          onBack={onBack}
          stepProgression={
            <StepProgression currentStep={step + 1} totalSteps={3} />
          }
        />
      }
      description={
        step > 0 ? (
          <Space direction="vertical" style={{ marginBottom: '1rem' }}>
            <Typography variant="subHeader-16">
              <T
                _str="Notification method: {method}"
                method={notificationModeLabelMap[other.mode]}
              />
            </Typography>
            <Typography variant="subHeader-16">
              <>
                {notificationModeContactMap[other.mode]}:{' '}
                {other[other.mode === 'sms' ? 'phoneNumber' : 'email']}
              </>
            </Typography>
          </Space>
        ) : undefined
      }
      mainContent={<StepContent />}
    />
  );
};

export default ViewSelfSerivce;
