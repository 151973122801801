import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Col, Row, Button } from 'antd';
import { T } from '@transifex/react';
import { RightOutlined } from '@ant-design/icons';
import { darken } from 'polished';
import { createPrefixer } from '../../../../../../../utils';

const p = createPrefixer('wh-custom-button-');

const classes = {
  wrapper: p`wrapper`,
  arrowOutlined: p`arrow-outlined`,
  icon: p`icon`,
  title: p`title`,
  description: p`description`,
  mainContent: p`main-content`,
  iconAndTitleWrapper: p`icon-and-title-wrapper`,
};

export type CustomButtonProps = {
  icon: ReactNode;
  title: string | ReactNode;
  onClick: () => void;
};

const CustomButtonDiv = styled.div`
  width: 100%;

  .ant-btn {
    width: inherit !important;
    height: auto !important;
    padding: 0 !important;
    background: #edf6ff;
    border: white 0px none;
    white-space: normal;
  }

  .ant-btn-round {
    border-radius: 8px !important;
  }

  .ant-btn:not([disabled]):hover {
    .${classes.mainContent} {
      background: ${(props) => darken(0.2, props.theme.primaryColor)};
    }

    .${classes.arrowOutlined} {
      background: ${(props) => darken(0.2, props.theme.primaryColor)};
      color: ${(props) => props.theme.lightColor};
    }

    .${classes.icon} {
      color: ${(props) => props.theme.lightColor};
    }

    .${classes.title} {
      color: ${(props) => props.theme.lightColor};
    }

    .${classes.description} {
      color: ${(props) => props.theme.lightColor};
    }
  }

  .${classes.iconAndTitleWrapper} {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .${classes.arrowOutlined} {
    display: none;
  }

  .${classes.icon} {
    font-size: 3.313rem;
    color: ${(props) => props.theme.darkColor};
  }

  .${classes.title} {
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => props.theme.darkColor};
  }

  .${classes.mainContent} {
    width: 100%;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 8px 8px 8px 8px;
  }

  @media screen and (min-width: 992px) {
    width: calc(100%);

    .ant-btn {
      width: inherit !important;
    }

    .${classes.iconAndTitleWrapper} {
      align-items: center;
      justify-content: start;
      flex-direction: row;
    }

    .${classes.arrowOutlined} {
      background: #c1e1ff;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      border-radius: 0 8px 8px 0;
    }

    .${classes.icon} {
      font-size: 1.313rem;
      margin-right: 1rem;
    }

    .${classes.title} {
      font-size: 1.25rem;
    }

    .${classes.mainContent} {
      padding: 1.5rem;
      border-radius: 8px 0 0 8px;
    }
  }
`;

const CustomButton: React.FC<CustomButtonProps> = ({
  icon,
  title,
  onClick,
}) => {
  return (
    <CustomButtonDiv className={classes.wrapper}>
      <Button shape="round" onClick={onClick}>
        <Row>
          <Col md={24} lg={22} className={classes.mainContent}>
            <Row>
              <Col span={24}>
                <div className={classes.iconAndTitleWrapper}>
                  <div className={classes.icon}>{icon}</div>
                  <div className={classes.title}>
                    {typeof title === 'string' ? (
                      <T _str="{title}" title={title} />
                    ) : (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>{title}</>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={0} lg={2} className={classes.arrowOutlined}>
            <RightOutlined style={{ fontSize: 'inherit' }} />
          </Col>
        </Row>
      </Button>
    </CustomButtonDiv>
  );
};

export default CustomButton;
