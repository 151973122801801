import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { T } from '@transifex/react';
import { createPrefixer } from '../../utils';
import { formatPayment } from '../../utils/currency';

const p = createPrefixer('wh-UI-Breadcrumb-simple-');

const classes = {
  wrapper: p`wrapper`,
  changeType: p`change-type`,
  change: p`change`,
  changeAmount: p`change-amount`,
  totalPrice: p`total-price`,
};

export type SelectionSummaryProps = {
  changes: {
    amount: string | ReactNode;
    type: string | ReactNode;
  }[];
  amount: number;
};

type WrapperProps = {
  $amount: boolean;
};

const SelectionSummaryDiv = styled.div<WrapperProps>`
  width: 100%;
  height: auto;
  padding: 1rem;
  background: #edf6ff;
  border-radius: 0.5rem;
  display: none;

  .${classes.changeType} {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
  }

  .${classes.change} {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: #0f3f6b;
  }

  .${classes.changeAmount} {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: #1890ff;
    margin-right: 0.5rem;
  }

  .${classes.totalPrice} {
    display: none;
  }

  ${({ $amount }) =>
    $amount &&
    `
      display: inherit;
  `};

  @media screen and (min-width: 992px) {
    .${classes.changeType} {
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 600;
      color: #0f3f6b;
    }

    .${classes.change} {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .${classes.changeAmount} {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
      color: #1890ff;
    }

    .${classes.totalPrice} {
      display: inherit;
      font-size: 0.875rem;
      line-height: 1.375rem;
      font-weight: 600;
      color: #0f3f6b;
    }
  }
`;

export const SelectionSummary: FC<SelectionSummaryProps> = ({
  changes,
  amount,
}) => {
  return (
    <SelectionSummaryDiv $amount={amount > 0} className={classes.wrapper}>
      <div className={classes.changeType}>
        {changes.map((change) => (
          <>
            <div className={classes.changeAmount}>{change.amount}</div>
            <div className={classes.change}>{change.type}</div>
          </>
        ))}
      </div>
      <div className={classes.totalPrice}>
        <T _str="Total: {amount}" amount={formatPayment(amount, 'dkk')} />
      </div>
    </SelectionSummaryDiv>
  );
};
