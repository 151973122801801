import React from 'react';
import { T } from '@transifex/react';
import { Modal } from 'antd';
import { useUserStore } from 'app/User.store';
import axios from 'axios';
import moment from 'moment';

let showSessionTimeoutModal = false;

const baseURL =
  localStorage.getItem('REACT_APP_API_URL') || process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(function automaticLogoutSessionHandler(config) {
  //  check for token validation here.
  if (config.headers?.['X-API-Key']) {
    //  then it's means it an authenticated token request.
    const tokenExpiry = localStorage.getItem('AUTH_TOKEN_EXPIRY');
    if (
      !tokenExpiry ||
      (moment().isAfter(moment(tokenExpiry)) && !showSessionTimeoutModal)
    ) {
      //  then it means that the token is expired so logout.

      Modal.warn({
        title: <T _str="Session timeout. Login again" />,
        onOk: useUserStore.getState().logout,
        mask: true,
        maskStyle: {
          background: 'white',
        },
      });
      showSessionTimeoutModal = true;
    }
  }

  return config;
});

// On apiClient we don't set the automaticLogoutSessionHandler Interceptor because it will not be used inside app rather by auth layer only.

const apiClient = axios.create({ baseURL });

const setToken = (token: string) => {
  apiClient.defaults.headers.common['X-API-Key'] = token;
};

const apiClientMethods = { setToken };
export { apiClient, apiClientMethods };
