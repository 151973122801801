import React from 'react';
import styled from 'styled-components';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { T } from '@transifex/react';

const TicketAlertWrapper = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  margin-bottom: 1rem;

  width: 100%;
  height: 100%;

  /* Dust Red / red-1 */

  background: #fff1f0;
  /* Dust Red / red-3 */

  border: 1px solid #ffa39e;
  border-radius: 2px;

  .icon {
    font-size: 1.35rem;
    color: #f5222d;
    margin-right: 1rem;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
  }

  @media screen and (min-width: 992px) {
    width: 50%;
    margin-bottom: 3rem;
  }
`;

export type OpenTicketsOnPropertyAlertProps = {
  linkTo?: string;
  tickets: $TSFixMe[];
};

export const OpenTicketsOnPropertyAlert = ({
  linkTo,
  tickets,
}: OpenTicketsOnPropertyAlertProps) => {
  return (
    <TicketAlertWrapper>
      <div className="descriptionWrapper">
        <ExclamationCircleFilled className="icon" />
        <div>
          <div style={{ fontSize: '1rem' }}>
            {tickets.length}{' '}
            {tickets.length > 1 ? <T _str="tickets" /> : <T _str="ticket" />}{' '}
            <T _str="open on the property" />
          </div>
          {tickets?.map((ticket: $TSFixMe) => (
            <div>
              <Link to={`${linkTo}/${ticket?.id}`}>
                <T _str="Ticket" /> {ticket?.ticket_id} - {ticket?.ticket_type}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </TicketAlertWrapper>
  );
};
