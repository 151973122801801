import { MutateFunction } from 'react-query';
import axios from 'axios';
import { message } from 'antd';
import React from 'react';
import { T } from '@transifex/react';
import { APIError } from '../consts/error';

type TVerificationType = 'sms' | 'email';

type TVariables = {
  token: string;
  verification_type: TVerificationType;
  phone_number?: string;
  email?: string;
};

export const mutateSendVerificationCode: MutateFunction<
  { data: boolean },
  unknown,
  TVariables
> = ({ token, verification_type, email, phone_number }) =>
  axios
    .post(
      `notifications/send-verification-code`,
      {
        verification_type,
        email,
        phone_number,
      },
      {
        headers: {
          'X-API-Key': token,
        },
      }
    )
    .catch(() => {
      message.error(<T _str="Invalid phone number" />);
      throw new APIError('invalid_phone_number', {
        ERROR_MESSAGE_HANDLED: true,
      });
    });
