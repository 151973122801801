import React, { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import RouterFactory from '../../routing/child-router-factory';

// eslint-disable-next-line import/no-cycle
import { createConfig } from './route-config';
// eslint-disable-next-line import/no-cycle
import { RouterContext } from '../../context/routing';

const MainRouter = () => {
  const routing = useContext(RouterContext);

  const map = routing.routingMap;
  return (
    <RouterFactory
      config={createConfig(map)}
      defaultRedirect={map.app.selfService._}
    />
  );
};

export default MainRouter;
